import './sales-rules.css';

const SalesRuls = () =>{
    return(
        <div>
        <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className="wrapper-terms">
                    <h5 className='pb-2'>
                        Правила продажи товаров
                    </h5>
                    <p>
                    Настоящие Правила регулируют отношения между НПД Еленой Подлубной, находящейся в сети Интернет по адресу www.elenaalekseeva.ru , и Покупателем при продаже Товаров через Интернет-сайт «Elena Alekseeva» и оказании в связи с такой продажей сопутствующих услуг. 
                    </p>
                    <p>
                    Настоящие Правила является юридически обязывающим Соглашением по продаже товаров и оказания сопутствующих услуг между НПД Еленой Подлубной и Покупателем/Получателем товаров.
                    </p>
                    <p>Настоящие Правила вступают в силу 1 июля 2023 года.</p>
                    <p className="text-uppercase">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</p>
                    <p>Основные понятия, используемые в настоящих Правилах, означают следующее:</p>
                    <p><b>Пользователь</b> – полностью дееспособное физическое лицо, желающий разместить Заказ на Сайте, осуществляющий иные действия, выражающие его заинтересованность в Товарах исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.</p>
                    <p><b>Покупатель</b> – Пользователь Сайта, прошедший регистрацию на Сайте путем предоставления Продавцу индивидуальную информацию о себе (Фамилию, Имя, Отчество (при наличии), почтовый адрес, адрес электронный почты (e-mail), адрес доставки, банковские реквизиты, номер телефона), которая будет использована для размещения Заказа.</p>
                    <p><b>Продавец</b> – НПД Елена Подлубная, осуществляющая продажу Товаров в соответствии с настоящими Правилами путем ознакомления Покупателя с описанием Товара с возможностью последующим оформлением Заказа. </p>
                    <p><b>Сайт</b> – имеющий адрес в сети Интернет www.elenaalekseeva.ru  , принадлежащий Продавцу. На нем представлены Товары, предлагаемые Продавцом Покупателям для оформления Заказов, а также условия оплаты и доставки этих Заказов Покупателям. Товар – объект соглашения сторон, ювелирные изделия в ассортименте, представленные на Сайте.</p>
                    <p><b>Заказ</b> – оформление сделки по покупке Товара, содержащее наименование, ассортимент, количество, стоимость и иные параметры выбранного Покупателем Товара с помощью Веб-интерфейса Сайта и отражается в личном кабинете Покупателя. </p>
                    <p><b>Оферта</b> – это настоящие Правила, опубликованные в сети Интернет по адресу www.elenaalekseeva.ru и принятые Покупателем при оформлении Заказа.</p>
                    <p><b>Акцепт</b> – полное и безоговорочное принятие Оферты путем осуществления действий по оплате Товара по соответствующему Заказу. </p>
                    <p><b>Каталог</b> – информация о Товарах, размещенная на Сайте.</p>
                    <p><b>Служба доставки</b> – Доставка курьером по России, самовывоз в Москве, доставка в другую страну, оказывающая услуги по доставке Товара Покупателю. </p>
                    <p><b>Личный кабинет</b> – закрытый (защищенный) раздел Сайта, расположенный по адресу: www.elenaalekseeva.ru, позволяющий Покупателю после прохождения регистрации на Сайте и при условии соблюдения условий Пользовательского соглашения оформить Заказ, а также совершать иные действия в рамках исполнения настоящих Правил.</p>
                    <p><b>Карта</b> – банковская карта, эмитированная кредитной организацией (банком), позволяющая Покупателю совершать с ее помощью операции с денежными средствами, находящимися у эмитировавшей Карту кредитной организации, в том числе операции по оплате Товаров, приобретаемых у Продавца.</p>
                    <p><b>Программа бонусов (Программа лояльности)</b> – программа вознаграждения Покупателей, построенная на системе накопления и использования бонусов на сопутствующие услуги Продавца.</p>
                    <p><b>Пользовательское соглашение</b> – правила и условия использования Пользователем/Покупателем Сайта, опубликованный в сети Интернет по адресу www.elenaalekseeva.ru.</p>
                    <p><b>Политика конфиденциальности</b> – правила и условиями обработки персональной информации Пользователя и/или Покупателя Сайта, опубликованный в сети Интернет по адресу www.elenaalekseeva.ru.</p>
                    <p className="text-uppercase">
                     2. ОБЩИЕ ПОЛОЖЕНИЯ  
                    </p>
                    <p>2.1. Настоящие Правила, также информация о Товаре, представленная на Сайте, являются Офертой.</p>
                    <p>2.2. Настоящие Правила содержат все существенные условия по покупке Покупателем Товара. Продавец оставляет за собой право вносить изменения в настоящие Правила, в связи с чем, Покупатель должен ознакомиться с ними при каждом оформлении Заказа.</p>
                    <p>2.3. Пользователь/Покупатель согласен с тем, что факт выполнения определенных действий на Сайте, в том числе с использованием технических инструментов, автозагрузки, выполнение команд через интерфейсные элементы Сайта (нажатие кнопок, клики), совершение предварительного платежа и иные подобные действия, означают волеизъявление Покупателя в отношении Заказа. </p>
                    <p>2.4. Действия, совершенные через Личный кабинет с использованием логина и пароля Покупателя, признаются сторонами, совершенными Покупателем и имеющими силу простой электронной подписи. </p>
                    <p>2.5. Товары, реализуемые Продавцом через Сайт, не подлежат дальнейшей перепродаже. Покупатель гарантирует, что Товары приобретаются им для собственных нужд.</p>
                    <p>2.6. Каждая Сторона гарантирует другой Стороне, что обладает всеми правами и полномочиями, необходимыми и достаточными для оформления Заказа.</p>
                    <p>2.7. Оформив Заказ, Покупатель полностью и безоговорочно соглашается с настоящими Правилами.</p>
                    <p>2.8. Материалы, представленные на Сайте, включая все его содержимое, информацию, фотографии, иллюстрации, рисунки, наименования и логотипы, являются собственностью НПД Елены Подлубной и/или его поставщиков и производителей Товара и защищаются законодательством Российской Федерации об авторском праве и товарных знаках и иных правах на интеллектуальную собственность. Содержание Сайта, как описано выше, не может быть изменено, скопировано, воспроизведено, продано, дополнено или использовано любым другим способом без предварительного письменного разрешения от Продавца.</p>
                    <p className="text-uppercase">3. РЕГИСТРАЦИЯ И ОФОРМЛЕНИЕ ЗАКАЗА НА САЙТЕ </p>
                    <p>3.1. Заказ Товара и сопутствующих услуг Продавца оформляется Покупателем самостоятельно на Сайте через Личный кабинет. </p>
                    <p>3.2. Оформить Заказ самостоятельно на Сайте могут только зарегистрированные Покупатели. </p>
                    <p>3.3. Пользователь, зарегистрировавшийся на Сайте, получает индивидуальную идентификацию путем предоставления логина и пароля Покупателя. </p>
                    <p>3.4. Индивидуальная идентификация Покупателя позволяет избежать несанкционированных действий третьих лиц от имени Покупателя и дает доступ к дополнительным сервисам Сайта. Передача Покупателем логина и пароля третьим лицам запрещена. После оформления Заказа, при условии полной предварительной оплаты стоимости Заказа, Покупателю через Личный кабинет или по указанному при регистрации на Сайте email предоставляется информация о дате передачи Заказа в Службу доставки, а именно: подтверждение оплаты Заказа получено Продавцом, информирование Покупателя о дате передачи Заказа в Службу доставки осуществляется в течение 3-х рабочих дней. </p>
                    <p>3.5. Заказ действителен в течение 5 (Пяти) календарных дней с момента его оформления Покупателем. По истечении вышеуказанного срока и при отсутствии предварительной полной оплаты, Заказ аннулируется.</p>
                    <p>3.6. Внешний вид Товара, включая цвет, могут незначительно отличаться от представленных на фотографии (свет, вспышка и т.п.). В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара, перед оформлением Заказа, Покупатель может обратиться к Продавцу, направив электронное письмо по адресу elenaalekssseeva@gmail.com .</p>
                    <p>3.7. В случае обнаружения Покупателем недостатков, которые не были оговорены Продавцом при продаже Товара, Покупатель вправе предъявлять требования о безвозмездном устранении недостатков, замене или возвратом денежных средств, если докажет, что недостатки возникли до передачи Товара Покупателю или по причинам, возникшим до передачи.</p>
                    <p>3.8. Продавец не несет ответственности за точность и правильность информации, предоставляемой Пользователем/Покупателем, в том числе при регистрации и оформлении Заказа. </p>
                    <p>3.9. Покупатель самостоятельно несёт ответственность за все возможные негативные последствия в случае передачи логина и пароля третьим лицам. В случае наличия ошибок или предоставления неполных сведений в полях «ФИО» и «Адрес доставки» Продавец полностью снимается себя ответственность за выполнение Заказа Покупателя.</p>
                    <p>3.10. В случае возникновения у Покупателя подозрений относительно безопасности его логина и пароля или возможности их несанкционированного использования третьими лицами, Покупатель обязуется незамедлительно уведомить об этом Продавца, направив электронное письмо по адресу elenaalekssseeva@gmail.com . </p>
                    <p>3.11. Продавец не несет ответственность за содержание информации, полученной Покупателем где-либо вне Сайта (реклама, отзывы, каталоги и др.).</p>
                    <p>3.12. Воспользоваться скидкой постоянного Покупателя и/или Программой бонусов можно только через Личный кабинет. Для этого необходимо оформлять Заказы под своим логином. Скидка постоянного Покупателя привязана к конкретному Личному кабинету, поскольку рассчитывается на основе покупок, осуществленных Покупателем через Личный кабинет. В случае повторной регистрации на Сайте история покупок не переносится из старого Личного кабинета во вновь созданный. </p>
                    <p className="text-uppercase">4.  СТОИМОСТЬ И ОПЛАТА ЗАКАЗА (ТОВАРА)</p>
                    <p>4.1. Цена Товара указывается в Российских рублях (RUB).</p>
                    <p>4.2. Оплата Заказа осуществляется Покупателем посредством банковской Карты или системы онлайн-платежей Яндекс-Кассы в случае прохождения Покупателем процедуры регистрации.</p>
                    <p>4.3. Продавец вправе отказать Покупателю в оплате Заказа посредством банковской Карты в случае:</p>
                    <ul className='rules-list ps-4'>
                        <li>
                        выявления признаков нарушения безопасности или мошенничества при использовании банковской Карты;
                        </li>
                        <li>банковская Карта блокирована;</li>
                        <li>отсутствия или недостатка денежных средств на счете банковской Карты для совершения операции;</li>
                        <li>частичной оплаты Заказа;</li>
                        <li>в связи с иными обстоятельствами.</li>
                    </ul>
                    <p>4.4. Продавец вправе потребовать документальное подтверждение оплаты Покупателем суммы Заказа. Сканы документов по требованию Продавца необходимо высылать на электронную почту по адресу elenaalekssseeva@gmail.com . Продавец гарантирует конфиденциальность данных, предоставленных Покупателем.</p>
                    <p>4.5. Заказ Покупателя принимается в обработку только после зачисления соответствующих денежных средств Покупателя на расчетный счет Продавца в соответствии с п.3.4. настоящих Правил.</p>
                    <p>4.6. Во избежание случаев различного рода неправомерного использования банковских Карт при оплате все Заказы, оформленные в Личном кабинете, могут быть проверены Продавцом. В целях проверки личности владельца и его правомочности на использование Карты Продавец вправе потребовать от Покупателя, оформившего такой Заказ, предъявления копии документа, удостоверяющего личность через Личный кабинет. Продавец оставляет за собой право без объяснения причины аннулировать Заказ. В этом случае, стоимость Заказа возвращается на банковскую Карту владельца в соответствии с п. 4.9. настоящих Правил.  </p>
                    <p>4.7. Продавец вправе предоставлять скидки на Товары и устанавливать Программу бонусов. Виды скидок, бонусов, порядок и условия начисления указаны на Сайте и могут быть изменены Продавцом в одностороннем порядке.</p>
                    <p>4.8. В случае отсутствия заказанных Покупателем Товаров на складе Продавца, последний вправе исключить отсутствующий Товар из Заказа и/или аннулировать Заказ Покупателя, в случае если, заказанные Покупателем Товары, отсутствуют на складе Продавца. Об исключении из Заказа Покупателя Товара, отсутствующего на складе, и/или аннулировании Заказа Покупателя в соответствии с условиями настоящего пункта, Продавец уведомляет Покупателя посредством направления соответствующего электронного сообщения по адресу, указанному Покупателем при регистрации на Сайте и/или оформлении Заказа. В случае исключения из Заказа Покупателя Товара, отсутствующего на складе, и/или аннулировании Заказа в соответствии с условиями настоящего пункта, денежные средства, оплаченные Покупателем за исключенный Товар и/или аннулированный Заказ, подлежат возврату Покупателю способом, которым Товар был оплачен, в течение 14 (четырнадцати) рабочих дней с даты получения Продавцом соответствующего электронного сообщения (требования) Покупателя на электронную почту по адресу elenaalekssseeva@gmail.com .</p>
                    <p>4.9. В случае неверного указания цены заказанного Покупателем Товара, Продавец при первой возможности информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо аннулирования Заказа. При невозможности связаться с Покупателем данный Заказ считается аннулированным в течение 5 (Пяти) календарных дней с момента оформления. Если Заказ был оплачен, денежные средства возвращаются Покупателю в порядке, предусмотренном п. 4.8 настоящих Правил. </p>
                    <p>4.10. Цена Товара может быть изменена Продавцом в одностороннем порядке. Цена Товара действительна на момент оформления Заявки. При этом цена на заказанный и предоплаченный Покупателем Товар изменению не подлежит. </p>
                    <p>4.11. Датой оплаты Заказа считается дата зачисления денежных средств на расчетный счет Продавца.</p>
                    <p>4.12. Все расходы, связанные с переводом денежных средств (комиссии (сборы), взимаемые кредитными организациями (платежными системами), при осуществлении оплаты по оформленному Заказу несет Покупатель.</p>
                    <p className="text-uppercase">5. ДОСТАВКА, ПРИЕМКА И ВОЗВРАТ ТОВАРА</p>
                    <p>5.1. Отправка Товаров по адресу, указанному в Заказе, осуществляется Продавцом только после полной предварительной оплаты Покупателем суммы Заказа.</p>
                    <p>5.2. Доставка заказанного Товар на основании Заказа осуществляется Службой доставки.</p>
                    <p>5.3. Стоимость доставки Товара указывается в Заказе.</p>
                    <p>5.4. Задержки в доставке Товара возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца.</p>
                    <p>5.5. При доставке Заказ вручается Покупателю либо третьему лицу, указанному в Заказе в качестве получателя (далее третье лицо, указанное в Заказе, именуется «Получатель»).</p>
                    <p>5.6. Во избежание случаев мошенничества, а также для выполнения взятых на себя обязательств, указанных в разделе 5 настоящий Правил, при вручении предоплаченного Заказа лицо, осуществляющее доставку Заказа, вправе затребовать документ, удостоверяющий личность Получателя, а также указать номер Заказа.</p>
                    <p>5.7. Риск случайной гибели или случайного повреждения Товара переходит к Покупателю с момента передачи Заказа Службу доставки. В случае недоставки Заказа по вине Службы доставки Продавец возмещает Покупателю стоимость предоплаченного Покупателем Заказа в течение 5 месяцев со дня передачи Заказа Покупателем в Службу доставки.</p>
                    <p>5.8. Стоимость доставки каждого Заказа рассчитывается индивидуально, исходя из веса Товара, места и способа доставки, и указывается при оформлении Заказа.</p>
                    <p>5.9. Порядок, стоимость доставки и раскрытия Заказа (отправления), содержащих Товар, определяется действующими Правилами СДЭК.</p>
                    <p>5.10. При получении Заказа в Службе доставки Получатель обязан осмотреть доставленный Товар и произвести его вскрытие в присутствии работников Службы доставки для проверки Товара на соответствие заявленному количеству, ассортименту и комплектности Товара, а также проверить целостность упаковки. В случае наличия претензий к доставленному Товару (недовложение, вложение Товара отличного от указанного в Заказе, производственный брак, иные претензии) по указанию Получателя работниками Службы доставки составляется Акт о выявленных несоответствиях. Если Получателем не были заявлены претензии в вышеуказанном порядке, то Продавец считается полностью и надлежащим образом, исполнившим свою обязанность по передаче Товара. В случае возврата доставленного посредством Службы доставки в связи с наличием претензий к Товару Получатель обязан приложить к отправлению, содержащему возвращаемый Товар, следующие документы:</p>
                    <ul className='rules-list ps-4'>
                        <li>заявление на возврат денежных средств;</li>
                        <li>копию акта о выявленных несоответствиях;</li>
                        <li>копию квитанции об оплате;</li>
                        <li>копию описи Отправления;</li>
                        <li>бланк возврата.</li>
                    </ul>
                    <p>5.11. При принятии Заказа от курьера Службы доставки, Получатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному количеству, ассортименту и комплектности Товара, а также проверить и целостность упаковки. В случае отсутствия претензий к доставленному Товару Получатель расписывается в доставочных документах Службы доставки. Подпись в доставочных документах свидетельствует о том, что претензий к Товару Получателем не заявлено и Продавец полностью и надлежащим образом выполнил свою обязанность по передаче Товара.</p>
                    <p>5.12. Пользователь понимает и соглашается с тем, что осуществление доставки – отдельная услуга, не являющаяся неотъемлемой частью приобретаемого Покупателем Товара, выполнение которой заканчивается в момент получения Получателем Товара. Претензии к качеству приобретенного Товара, возникшие после получения Товара, рассматриваются в соответствии гарантийными обязательствами Продавца. В связи с этим приобретение Товара с доставкой не дает Покупателю право требования доставки приобретенного Товара в целях гарантийного обслуживания или замены, не дает возможности осуществлять гарантийное обслуживание или замену Товара посредством выезда к Покупателю и не подразумевает возможность возврата стоимости доставки Товара в случаях, когда Покупатель имеет право на возврат денег за Товар как таковой.</p>
                    <p className="text-uppercase">6. ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>
                    <p>6.1. Продавец обязуется: </p>
                    <ul className='rules-list ps-4'>
                        <li>передать Покупателю оплаченный Товар в соответствии с оформленным Заказом и условиями настоящий Правил. Продавец оставляет за собой право невыполнения обязательств по настоящим Правилам в случае возникновения обстоятельств непреодолимой силы;</li>
                        <li>обрабатывать персональные данные Покупателя/Получателя и обеспечивать их конфиденциальность в порядке, установленном Политикой о конфиденциальности.</li>
                    </ul>
                    <p>6.2. Продавец имеет право: </p>
                    <ul className='rules-list ps-4'>
                        <li>изменять настоящие Правила, цены на Товар, стоимость услуг на сопутствующие услуги, способы и сроки оплаты и доставки Товара в одностороннем порядке, размещая их на Сайте. Все изменения вступают в силу немедленно после публикации, и считаются доведенными до сведения Покупателя с момента такой публикации;</li>
                        <li>расширять и сокращать товарное предложение на Сайте, регулировать доступ к покупке любых Товаров, а также приостанавливать или прекращать продажу любых Товаров по своему собственному усмотрению;</li>
                        <li>без согласования с Покупателем передавать свои права и обязанности по исполнению настоящих Правил третьим лицам;</li>
                        <li>требовать полной оплаты оформленного Заказа. Продавец имеет право отказать Покупателю в передаче Товара при отсутствии такой оплаты;</li>
                        <li>на изменение элементов дизайна Товара.</li>
                    </ul>
                    <p>6.3. Продавец имеет иные права, предусмотренные Пользовательским соглашением, Политикой конфиденциальности, Программой бонусов.</p>
                    <p>6.4. Покупатель обязуется: </p>
                    <ul className='rules-list ps-4'>
                        <li>до момента оформления Заказа ознакомиться с содержанием и условиями настоящих Правил, ценами на Товар, предлагаемыми Продавцом на Сайте;</li>
                        <li>сообщить Продавцу все необходимые данные, однозначно идентифицирующие его как Покупателя и достаточные для доставки Покупателю оплаченного им Товара;</li>
                        <li>оплатить заказанный Товар и его доставку на условиях настоящих Правил;</li>
                        <li>в момент получения Товара внимательно его осмотреть в присутствии работников Службы доставки и убедиться, что Товар полностью соответствует Заказу (количеству, ассортименту и комплектности Товара) и механические и иные внешние повреждения Товара отсутствуют; </li>
                        <li>соблюдать Пользовательское соглашение, Политику конфиденциальности, Программу бонусов.</li>
                    </ul>
                    <p>6.5. Покупатель имеет право осуществлять возврат полученного Товара в соответствии с разделом 5 настоящих Правил.</p>
                    <p className="text-uppercase">7. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ </p>
                    <p>7.1. Продавец не гарантирует соответствие Товара и сервисов на Сайте ожиданиям Покупателя.</p>
                    <p>7.2. Продавец вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</p>
                    <p>7.3. Покупатель обязуется не использовать заказанный Товар в предпринимательских целях.</p>
                    <p>7.4. Признание судом недействительности какого-либо положения настоящих Правил не влечет за собой недействительность остальных положений.</p>
                    <p className="text-uppercase">8. КОНФИДЕНЦИАЛЬНОСТЬ </p>
                    <p>8.1. Предоставляемая Покупателем информация при регистрации на Сайте является конфиденциальной информацией.</p>
                    <p>8.2. Предоставляя свои персональные данные при регистрации на Сайте, Покупатель соглашается на их обработку Продавцом в соответствии с Политикой о конфиденциальности, в том числе и в целях продвижения Продавцом Товаров.</p>
                    <p>8.3. Продавец использует информацию: </p>
                    <ul className='rules-list ps-4'>
                        <li>для регистрации Покупателя на Сайте;</li>
                        <li>для выполнения своих обязательств перед Покупателем;</li>
                        <li>для оценки и анализа работы Сайта;</li>
                        <li>для осуществления иных действий, предусмотренных Политикой о конфиденциальности.</li>
                    </ul>
                    <p>8.4. Продавец вправе направлять Покупателю сообщения рекламно-информационного характера. Если Покупатель не желает получать рассылки от Продавца, он должен отказаться от подписки путем «клика» по соответствующей ссылке в направляемом Продавцом письме. Для входа необходимо ввести логин и пароль.</p>
                    <p>8.5. Не считается нарушением настоящего раздела в случае предоставление Продавцом информации агентам и третьим лицам, действующим по поручению Продавца на основании договора (соглашения).</p>
                    <p>8.6. Продавец не несет ответственности за сведения, предоставленные Покупателем на Сайте в общедоступной форме.</p>
                    <p>8.7. В случае возникновения у Продавца подозрений относительно использования учетной записи Покупателя третьим лицом или вредоносным программным обеспечением Продавец вправе в одностороннем порядке изменить пароль Покупателя с уведомлением последнего на указанный при регистрации адрес электронной почты.</p>
                    <p className="text-uppercase">10. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                    <p>10.1. Продавец обязуется предпринимать все разумные усилия для передачи Покупателю/Получателю оплаченного Товара, однако не отвечает и не компенсирует убытки Покупателю в случае, если Продавец не может передать Товар по следующим причинам:</p>
                    <ul className='rules-list ps-4'>
                        <li>технологические неисправности каналов связи общего пользования, посредством которых осуществляется оформление/заказ Товаров, предоставления Покупателем необходимой информации по доставке Товара, отправка претензий, утраты Покупателем доступа в сети Интернет по любой причине, ошибки, пропуски, перерывы в работе или передаче данных, дефекты линий связи и иные технические сбои;</li>
                        <li>несанкционированное вмешательство в работу Сайта третьих лиц, включая хакерские, DDoS-атаки, действия вирусных программ и иные нарушения работы Сайта.</li>
                        <li>утрата Покупателем доступа к личному кабинету, в том числе при наличии признаков несанкционированного его использования;</li>
                        <li>в случаях, подпадающих под определение обстоятельств непреодолимой силы.</li>
                    </ul>
                    <p>10.2. В случае причинения убытков Покупателя по вине Продавца ответственность определяется в порядке, предусмотренном законодательством Республики Индонезия. При этом ответственность Продавца перед Покупателем определяется в размере, не превышающем стоимость заказанной и оплаченной Покупателем Товара в сумме на момент причинения убытков.</p>
                    <p>10.3. Продавец не отвечает перед Покупателем за убытки, возникшие у Покупателя не по вине Продавца, в том числе в связи с нарушением Покупателем Пользовательского соглашения, Политики о конфиденциальности, Бонусной программой и иных документов, пояснений, размещенных Продавцом на Сайте.</p>
                    <p>10.4. Покупатель проинформирован и соглашается с тем, что операции (действия) по приему оплаты за Товар не контролируется и не отслеживается Продавцом лично или через третьих лиц, Продавец не несет ответственности за перевод и/или поступление денежных средств Покупателя в оплату Товара. Безопасность, конфиденциальность, а также иные условия использования систем оплаты по банковской карте определяются соглашениями между Покупателем и соответствующими кредитными организациями.</p>
                    <p>10.5. Покупатель отвечает за любые действия, совершенные с использованием логина/пароля Покупателя на Сайте, адреса электронной почты, реквизитов банковской Карты, адреса доставки, номера телефона (в том числе за действия третьих лиц), а также сохранность своего логина/пароля и за убытки, которые могут возникнуть по причине несанкционированного использования личного кабинета Покупателя и простой электронной подписи.</p>
                    <p>10.6. Продавец не несет ответственность за действия третьих лиц, повлекшую кражу/утерю логина или пароля Покупателя, а также любые расходы, ущерб и/или упущенную выгоду, возникшие у Продавца в связи с несанкционированным доступом третьих лиц к личному кабинету Продавца.</p>
                    <p>10.7. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, заказанных на Сайте.</p>
                    <p>10.8. Продавец не несет ответственности за содержание и функционирование Внешних сайтов.</p>
                    <p className="text-uppercase">11. РАССМОТРЕНИЯ ПРЕТЕНЗИЙ</p>
                    <p>11.1. Споры, возникшие в рамках настоящих Правил, решаются в претензионном порядке. Срок рассмотрения таких претензий составляет не более 30 (Тридцати) дней с момента получения претензии Продавцом.</p>
                    <p>11.2. В случае если ответ на претензию не будет получен Покупателем в течение 30 (тридцати) дней с даты направления соответствующей претензии, либо если Стороны не придут к соглашению по возникшим претензиям и/или разногласиям, спор подлежит передаче на рассмотрение в суд в соответствии с законодательством Российской Федерации.</p>
                    <p className="text-uppercase">12. ПРОЧИЕ УСЛОВИЯ</p>
                    <p>12.1. Гарантийный срок на Товары составляет 1 неделя со дня получения Товара. Гарантия не распространяется на дефекты, образовавшиеся в результате:</p>
                    <ul className='rules-list ps-4'>
                        <li>механических повреждений (царапин, разрывов, потертостей и т. д.);</li>
                        <li>воздействия экстремальных температур, растворителей, кислот, воды;</li>
                        <li>неправильного использования (эксплуатации);</li>
                        <li>естественного износа.</li>
                    </ul>
                    <p>12.2. Продавец вправе изменять и/или дополнять условия настоящих Правил, а также отозвать Правила в любое время. Датой изменения Правил является дата опубликования на Сайте новой редакции Правил. Покупатель должен ознакомиться с действующей редакцией Правил, размещенной на Сайте, до оформления Заказа.</p>
                    <p>12.3. Оформление Заказа после вступления в силу изменений Правил означает, что стороны пришли к согласию о внесении соответствующих изменений и/или дополнений в Правила. Такие изменения не применяются к Заказам, заказанным и оплаченным Покупателем ранее, до вступления в силу новых изменений в Правилах. </p>
                    <p>12.4. Настоящие Правила представляют все условия, согласованные между Сторонами в отношении Заказа, и заменяют собой все прежние договоренности, заверения и любого рода соглашения между Сторонами, если иное прямо не согласовано Сторонами.</p>

            


                    


                    



                </div>
            </div> 
        

    </div>

    )
    
    

}

export default SalesRuls;