import { useParams, Link } from 'react-router-dom';
import React, { useEffect, useState, useContext } from "react";
import categoryMenu from '../categoryMenu';
import {CartContext} from '../cart-context/cart-context';
import ApiService from '../../services';
import SideNavbar from '../side-navbar/side-navbar';
import ListItemTabs from '../list-item-tabs/list-item-tabs';
import ListItemAccordion from '../list-item-accordion/list-item-accordion';
import SkeletonItem from '../skeleton-item/skeleton-item';
import SkeletonItemCarousel from '../skeleton-item-carousel/skeleton-item-carousel';

import "bootstrap-icons/font/bootstrap-icons.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 

import './shop-list-item.css';
import ListItemCarousel from '../list-item-carousel/list-item-carousel';


const ShopListItem = () => {

    const { id, category} = useParams();

    function findCategoryName (category) {
        const findCategory = categoryMenu.filter(item => item.tagname === category);
        if(findCategory.length>0){
            const {name}=findCategory[0];
            return name;
        } else return '';
    }

    const cart = useContext(CartContext);

    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
   

    const loadData = async () => {
        ApiService.getInstance().getProductById(id)
          .then(res => {
            console.log('resData', res.data);
              setProduct(res.data);  
              
              setLoading(false);  
        })
    };
    
    useEffect(() => {
        setLoading(true);
        loadData();
        return () => {};
    }, []);

    const {
        images,
        name,
        sku,
        price,
        sale_price,
        regular_price,
        stock_status,
        attributes
    } = product;
    

    const sizeAttribute = ['pa_ring_size', 'pa_bracelet_size'].reduce((acc, key) =>  
        acc || (attributes?.[key] ? key : undefined), undefined);

    const sizeVariation = attributes?.[sizeAttribute]?.options;
    const sizeSelectionRequired = sizeVariation?.length && !product?.size;


    return (
        <>
        <div>
            <div className="under-fix-nav">&nbsp;</div>
        </div>
        
            <div className="container wrapper-content">  
                <div className="row ">
                
                <SideNavbar selectedMenu={category} />
                <div className="col-xl-9">
                    <div className="row">
                        <div className='wrapper-small-add-nav col-12 d-lg-none d-flex justify-content-start'>
                            <Link to="/">
                                <div className="small-add-nav"> главная <i className="bi bi-chevron-right px-1"
                                 style={{fontSize: '10px'}}></i>
                                </div> 
                            </Link>
                            <Link to={`/catalog/${category}`}>
                                <div className="small-add-nav"> 
                                    {findCategoryName(category)}
                                </div>
                            </Link>

                            
                        </div>
                        <div className="col-12 col-md-6">
                            {loading && <SkeletonItemCarousel/>}
                            {!loading && 
                                <>
                                    <div className='wrapper-carousel'> 
                                    {images &&  <ListItemCarousel key={id} {...product}/> }
                                    </div>  
                                </>
                            }                          
                        </div>
                        <div className="col-12 col-md-6 my-2 ">
                            {loading && <SkeletonItem/>}
                            {!loading && 
                        <>
                            <h4>
                              <span className='product-name'>{name}</span>  
                            </h4>
                            <div className="descr-code mb-2 ">
                               арт {sku}
                            </div>
                            
                                {sale_price ? (
                                <div className="card-price-list-item my-3">
                                     <span className='product-price text-muted text-decoration-line-through'>{regular_price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span> <span className='product-price-sale'>{price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span>
                                </div>
                                                                      
                                ):(
                                <div className="card-price-list-item my-3">
                                    <span className="product-price">{price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span>
                                </div>  

                                )}
                            {/* <div className="item-variants my-2 ">
                                Цвет: <span>Black</span>
                                                         
                            </div> */}
                            <div className="my-2">
                                { stock_status === 'instock' ? (
                                    <>
                                        <div className='item-availible'>Товар в наличии</div>
                                        <span className='item-availible-terms'>Отгрузка в течение 1-3 рабочих дней</span>

                                    </>
                                ):( 
                                    <>
                                        <div className='item-for-order'>Товар под заказ</div>
                                        <span className='item-availible-terms'>Отгрузка в течение 30 дней</span>

                                    </>
                                )}
                            </div> 
                            <div className="my-3">
                                { sizeVariation?.length && 
                                    <>
                                        <div className='item-sizes mb-3'>Размеры:</div>
                                        {sizeVariation.map((item) => 
                                            <button key={item.slug}
                                            onClick={() => setProduct({
                                                ...product,
                                                size_slug: item.slug,
                                                size: item.name,
                                                size_attribute: sizeAttribute
                                            })}
                                            className={`size-variation ${item.slug === product.size_slug && "selected-size"}`}
                                            >{item.name}
                                            {/* <span className='tooltiptext'>размер&nbsp;{item.name}</span> */}
                                            </button>
        
                                        )}
                                    </>
                                }
                            </div>   
                            <button 
                            disabled= {sizeSelectionRequired}
                            className='btn btn-dark my-3 shop-list-btn' onClick={() => {console.log('add ', product); cart.append(product, 1);}}>
                                {`${!sizeSelectionRequired ? 'добавить в корзину' : 'выберите размер'}`}
                            </button>

                            <div className="wrapper-conditions ">
                                <div className='mt-2'>
                                    <i className="bi bi-truck"></i><span className='ps-2'> Бесплатная доставка от 20 000 <FontAwesomeIcon icon={solid('ruble-sign')} size="xs"/></span>
                                </div>
                                <div className='mt-2'>
                                    <i className="bi bi-globe"></i> <span className='ps-2'>Доставка в Республику Беларусь и Казахстан</span>
                                </div>
                               
                            </div>
                            
                        </>
                        }                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-none d-md-block my-4">
                            <ListItemTabs key={id} {...product}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 my-4 border-top border-bottom d-md-none">
                          <ListItemAccordion key={id} {...product}/>
                                                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )    
}

export default ShopListItem;