import { Link } from 'react-router-dom';
import "bootstrap-icons/font/bootstrap-icons.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVk} from "@fortawesome/free-brands-svg-icons";

import './footer.css';

const Footer =() => {
    return (
        <footer>
            <div className="footer-wraper">
                <div className="container wrapper-content">
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <h4 className="footer-title">покупателям</h4>
                            <ul>
                                <li>
                                    <Link to="/delivery-terms">Доставка и Оплата</Link>
                                </li>
                                <li>
                                    <Link to="/return-terms">Возврат и Обмен</Link>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="col-6 col-md-3 ">
                            <h4 className="footer-title">компания</h4>
                            <ul>
                                <li>
                                    <Link to="/about-us">О нас</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Контакты</Link>
                                </li>
                                <li>
                                    <Link to="/agreement">Согласие на обработку персональных данных</Link>
                                </li>
                                <li>
                                    <Link to="/privacyPolicy">Политика конфиденциальности</Link>
                                </li>
                                <li>
                                    <Link to="/sales-rules">Правила продажи товаров</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <h4 className="footer-title">
                                Мы в социальных сетях
                            </h4>
                            
                                    {/* <form className="footer-input ">
                                        <input type="email" className="input-group-field w-50" name="contact[email]" placeholder="enter your email here"/>
                                        <button type="submit" className="btn-footer btn-sm ">SUBMIT</button>
                                    </form> */}
                                    <section className="mb-4">
                                        <a className="btn btn-outline-light btn-floating m-1" href="https://vk.com/aleksseevaelena" role="button"><FontAwesomeIcon icon={faVk}/> </a>
                                        <a className="btn btn-outline-light btn-floating m-1" href="https://web.telegram.org/z/#-1324936203" role="button"><i className="bi bi-telegram"></i></a>
                                    </section> 

                             
                                                           
                        </div>
                    </div>
                    <div className="copyright">
                        © 2022 Copyright: ElenaAlekseeva.ru
                    </div>
                </div>
            </div>            
        </footer>

    )
}

export default Footer;