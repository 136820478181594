
const categoryMenu = [
    {
        url: "/catalog/shop-all",
        name: "Все товары",
        tagname: "shop-all",
        thumbnail: ''
    },
    {
        url: "/catalog/new",
        name: "Новинки",
        tagname: "new",
        thumbnail: ''
    },
    {
        url: "/catalog/earings",
        name: "Серьги",
        tagname: "earings",
        thumbnail: ''
    },
    {
        url: "/catalog/necklaces",
        name: "Ожерелья",
        tagname: "necklaces",
        thumbnail: ''
    },
    {
        url: "/catalog/rings",
        name: "Кольца",
        tagname: "rings"
    },
    {
        url: "/catalog/brooch",
        name: "Броши",
        tagname: "brooch"
    },
    {
        url: "/catalog/braslets",
        name: "Браслеты",
        tagname: "braslets"
    },
    {
        url: "/catalog/accessories",
        name: "Аксессуары",
        tagname: "accessories" 
    },
    {
        url: "/catalog/sale",
        name: "Sale",
        tagname: "sale" 
    }


]

export default categoryMenu;
