import './agreement-personal-data.css';

const AgreementPersonalData =()=> {
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className="wrapper-terms">
                    <h5 className='pb-2'>
                        Согласие на обработку персональных данных
                    </h5>
                    <p className='text-uppercase'>
                        В ТОМ ЧИСЛЕ НА ПОЛУЧЕНИЕ РАССЫЛОК, ИМЕЮЩИХ РЕКЛАМНЫЙ ХАРАКТЕР
                    </p>
                    <p>
                    Субъект персональных данных (далее «Пользователь»), направляя запрос (заявку, сообщение) на Интернет-сайт «Elena Alekseeva» (www.elenaalekseeva.ru ), принимает настоящее Согласие на обработку персональных данных (далее «Согласие»).
                    </p>
                    <p>
                    Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь при размещении (вводе) своих персональных дает свое согласие НПД Елене Подлубной, электронная почта: elenaalekssseeva@gmail.com , почтовый адрес: 454100, г. Челябинск, ул.Чичерина, д.5, кв. 316, на обработку своих персональных данных и на получение каких-либо рассылок, имеющих, в том числе рекламный характер, со следующими условиями:
                    </p>
                    <ol>
                        <li>
                        Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.
                        </li>
                        <li>
                        Согласие дается на обработку следующих моих персональных данных:
                            <ul className='agreement-list'>
                                <li>ФИО;</li>
                                <li>паспортные данные;</li>
   
                                <li>номера контактных телефонов; </li>
                          
                                <li>адрес электронной почты; </li>
                                <li>
                                адрес места жительства;
                                </li>
                                <li>
                                адрес места доставки товаров;
                                </li>
                                <li>
                                дату рождения;
                                </li>
                                <li>
                                банковские реквизиты / реквизиты платежных документов;
                                </li>
                                <li>
                                пользовательские данные (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт Пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес.
                                </li>
                            </ul>
                        </li>
                        <li>
                        Персональные данные не являются общедоступными.
                        </li>
                        <li>
                        Цель обработки персональных данных:
                            <ul className='agreement-list'>
                                <li>выполнение обязательств по вопросу исполнения заказа Пользователя;</li>
                                <li>обработка запросов Пользователя;</li>
                                <li>сбор аналитических данных о посещениях ( www.elenaalekseeva.ru );</li>
                                <li>оптимизации Сайта ( www.elenaalekseeva.ru );</li>
                                <li>проведение электронных опросов, анкетирования, рекламных и маркетинговых исследований (акций), в том числе касающихся услуг,оказываемых НПД Еленой Подлубной и/или третьими лицами в интересах НПД Елены Подлубной;</li>
                                <li>клиентская поддержка;</li>
                                <li>организация доставки товаров, осуществляемых третьими лицами в интересах НПД Елены Подлубной;</li>
                                <li>контроля удовлетворенности Пользователя, а также качества услуг, оказываемых НПД Еленой Подлубной, и/или третьими лицами в интересах НПД Елены Подлубной;</li>
                                <li>получения оплаты за товар.</li>
                            </ul>
                        </li>
                        <li>В ходе обработки с персональными данными будут совершены следующие действия:
                            <ul className='agreement-list'>
                                <li>сбор;</li>
                                <li>запись;</li>
                                <li>систематизация;</li>
                                <li>накопление;</li>
                                <li>хранение;</li>
                                <li>уточнение (обновление, изменение);</li>
                                <li>извлечение;</li>
                                <li>использование;</li>
                                <li>передача (распространение, предоставление, доступ);</li>
                                <li>блокирование;</li>
                                <li>удаление;</li>
                                <li>уничтожение;</li>
                                <li>трансграничная передача персональных данных.</li>
                            </ul>
                        </li>
                        <li>Обработка персональных данных прекращается по достижению цели обработки и прекращения обязательств по заключённым договорам и соглашениям.</li>
                        <li>Настоящее Согласие может быть отозвано Пользователем или его представителем путем направления письменного заявления по электронному адресу www.elenaalekseeva.ru .
                        </li>
                    </ol>

                </div>
                
            </div>            
        </div>

    )
        


}

export default AgreementPersonalData;