import { useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import './search-component.css';

import SearchComponentPreview from "../search-component-preview/search-component-preview";
import SearchComponentResult from "../search-component-result/search-component-result";


const SearchComponent = () =>{

    const[previewShow, setPreviewShow] = useState(false);
    const[searchShow, setSearchShow] = useState(false);
    const[searchString, setSearchString] = useState('');

    const handleChange =(e) =>{
        setSearchString(e.target.value);
        setPreviewShow(false);
        if(e.target.value===''){
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
    }

    const handleFocus =() => {
        setPreviewShow(true); 
    }

    function clearInput (e) {        
            setPreviewShow(false);
            setSearchShow(false);
            e.target.value = null;
    }

    const handleBlur =(e) =>{
        clearInput(e);    
    }

    const handleKeyDown =(e) =>{
        if(e.keyCode === 27){
            clearInput(e);   
        }
    }

    return(
        <div className="header-search">
            <div className="input-group">
                <input 
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}               
                type="text" className="form-control form-control-sm" 
                placeholder="поиск по сайту" 
                // aria-label="Username" 
                // aria-describedby="basic-addon1"
                />
                <button 
                className="btn btn-outline-secondary" 
                type="button"><i className="bi bi-search pe-2" ></i>
                </button>
               
            </div>
            <div className="wrapper-search-result">

             {previewShow && !searchShow && <SearchComponentPreview/>}
             
             {searchShow && <SearchComponentResult searchString={searchString}/>}

            </div>
        </div>       
    )

}
export default SearchComponent;
