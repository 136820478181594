import {Link} from 'react-router-dom';
import { Component } from 'react';
import ApiService from '../../services';
import SideNavbar from '../side-navbar/side-navbar';
import ContentCatalogLoading from '../content-catalog-loading/content-catalog-loading';
import SortComponent from '../sort-component/sort-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro' 
import "bootstrap-icons/font/bootstrap-icons.css";

import banner from './banner.jpeg';
import './content-catalog.css';
import ScrollButton from '../scroll-button/scroll-button';


export default class ContentCatalog extends Component {
    state = {
        products: [],
        bannerImg: '',
        bannerText: 'banner text',
        loading: false
    }


    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) { 
        if (this.props.selectedCatalog !== prevProps?.selectedCatalog ) {
            this.loadData();
        } 
    }

    onContentLoading=() => {
        this.setState ({
            loading: true
        })
    }

    loadData() {
        const slug = this.props.selectedCatalog;
        this.onContentLoading();  
        if(slug === 'shop-all'){
            this.setCategoryData({slug: null, name: 'Все товары', image: {src: banner}});
            ApiService.getInstance().getAllProducts().then(res => {
                    const products = res.data;
                    this.setState({products, loading: false});
            })
        } else if (slug) {
            ApiService.getInstance().getCategoryBySlug(slug).then(res => {
                const categoryData = res.data?.[0];
                categoryData.slug = slug;
                this.setCategoryData(categoryData);
            });
            ApiService.getInstance().getProductsByCategorySlug(slug).then(res => {
                    const products = res.data;
                    this.setState({products, loading: false});
            });
        }
    }

    setCategoryData(categoryData) {
        this.setState({
            bannerText: categoryData?.name || '-empty-',
            bannerImg: categoryData?.image?.src || '-empty-'
        });
    }

    
    render () {
        const {loading} = this.state;

        return(
            <>
            {loading &&  <ContentCatalogLoading selectedMenu={this.props.selectedCatalog}/>}
            {!loading && 
                <>  
                <div className="container wrapper-content">
                    <div className='under-fix-nav'></div>
                    <div className="row">
                        <div className='wrapper-small-add-nav col-12 d-lg-none d-flex justify-content-start'>
                            <Link to="/">
                                <div className="small-add-nav"> главная <i className="bi bi-chevron-right px-1"
                                 style={{fontSize: '10px'}}></i>
                                </div> 
                            </Link>
                            <Link to={`/catalog/${this.props.selectedCatalog}`}>
                                <div className="small-add-nav"> {this.state.bannerText}
                                </div>
                            </Link>   
                        </div>
                        <SideNavbar selectedMenu={this.props.selectedCatalog}/>
                        
                        <div className="col-xl-9 ">                    
                            <div className="row ">   
                                <div className="col-12">        
                                    <div className="banner-wrapper">    
                                        <img src={`${this.state.bannerImg}`} alt="banner-necklaces"/>
                                    </div>  
                                    <h4 className='content-catalog-title'>{`${this.state.bannerText}`}</h4>         
                                </div> 

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SortComponent/>
                                </div>
                                
                            </div>                           
                            <div className="row mb-4 " >       
                            {
                                this.state.products
                                .map(product =>
                                    <div className="col-md-4 col-6 d-flex  mb-3 wrapper-card px-2 "
                                    key={product.id}>
                                    <Link to={`/catalog/${this.props.selectedCatalog}/item/${product.id}/`}
                                        >                                    
                                        <div className="wrapper-img-card d-flex justify-content-center" >
                                            <img src={product.images?.[0]?.src} className=" img-card" alt="earing1" />
                                        </div>
                                        <div className="wrapper-card-content">
                                            <p className="card-name">{product.name}</p>
                                            {product.sale_price ? (
                                                <div className="card-price ">
                                                    <span className='product-price text-muted text-decoration-line-through'>{product.regular_price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span> <span className='product-price-sale'>{product.price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span>
                                                </div>
                                                                                    
                                                ):(
                                                <div className="card-price">
                                                    <span className="product-price">{product.price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></span>
                                                </div>  

                                            )}
                                           
                                        </div>                                                                            
                                    </Link>
                                    </div>
                                )
                            }                            
                            </div>
                        </div>                          
                    </div>                    
                </div>

                </>}
            </>
        )                 
    }             
}
