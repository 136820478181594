
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

export default class ApiService  {
    static data = null;
    
    api = null;

    // singleton
    static getInstance() {
        if (ApiService.data === null) {
            ApiService.data = new ApiService();
        }
        return ApiService.data;
    }

    constructor() {
        this.api = new WooCommerceRestApi({
            url: "/",
            consumerKey: "preview",
            consumerSecret: "preview",
            // consumerKey: "ck_2c1b0c148e23adb0da712e1eefeb449eb09b0352",
            // consumerSecret: "cs_9f6659e6d020349ce294ef3ade783ceeddccfb4e",
            //version: "wc/v3"
            version: "ea/v1"
        });
    }
    getCategoryBySlug(slug) {
        return this.api.get ('products/categories', {slug});
    }
    
    getProductsByCategory(category) {
        return this.api.get ('products', {category, per_page: 100});
    }

    getProductsByCategorySlug(slug) {
        return this.api.get ('products', {slug});
    }

    getAllProducts() {
        return this.api.get ('products', {per_page: 100});
    }

    getProductById(id) {
        const url = `products/${id}`;
        return this.api.get(url);
    }

    createOrder(orderData) {
        const url = `orders`;
        return this.api.post(url, orderData);
    }

    getOrderDetails(orderKey) {
     const url = `orders/${orderKey}`;
     return this.api.get(url);

    }
}   
