import OrderId from '../order-id/order-id';

import './orders.css';

const Orders =() =>{
    // if history is empty return message

    return(
        <div className="order-history">
            <div>
                <div className="under-fix-nav">&nbsp;</div>
            </div>

            <div className="container wrapper-content">
                <div className="row">
                    <div className="col-12">
                        <h4 className="header-order-history">
                                Ваши заказы
                        </h4>
                        <div className="wrapper-order-history">
                                                   
                            <OrderId/>
                            <OrderId/>
                                
                        </div>
                    </div>
                </div>

            </div>
        </div>
        
    )
}

export default Orders;
