import "bootstrap-icons/font/bootstrap-icons.css";

import {Link} from 'react-router-dom';
import {useContext} from 'react';
import { CartContext } from "../cart-context/cart-context";
import CartListItem from "../cart-list-item/cart-list-item";
import SpinnerCart from "../spinner-cart/spinner-cart";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import './cart-list.css';

const CartList =() => {
    const cart = useContext(CartContext);
   
    return (
        <>
        <div className="undernav"></div>
        <div className="cart-list-content position-relative pb-4"> 
        {cart.showSpinner&& <SpinnerCart/>}           
            <div className="container cart">  
            {cart.items.length ? (
            <div className="">
                <div className="row ">
                    <div className="col-12">
                        <h2 className="cart-list-header">
                                корзина
                        </h2>                
                        <div className="cart-items-lists mb-2">                    
                                {cart.items.map((item, index) => 
                                    <CartListItem key={index} {...item} />
                                )}                                                                            
                        </div> 
                    </div>
                </div>
                <div className="row  d-flex justify-content-end">
                    <div className="col-12 col-md-6  "> 
                        <div className="totals-lg p-3">
                            <div className="totals-border row pb-3 ">
                                <div className="col-7 ">
                                    <div> товары, {cart.items.length} </div>
                                </div>
                                <div className="col-5 d-flex justify-content-end">
                                    <div> {cart.itemsCost} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/> </div>
                                </div>
                            </div>
                            <div className="row my-3 shipping-line">
                              
                                <div className="col-12 d-flex justify-content-start">
                                    перейдите к оформлению заказа, чтобы рассчитать доставку
                                </div>
                            </div>
                            <div className="row">
                                <div className="cart-btn col-12 ml-auto my-3 ">
                                    <Link to="/checkout">
                                        <button className='btn btn-dark w-100'>
                                            оформить заказ
                                        </button>
                                    </Link> 
                                </div>
                            </div>
                        </div>
                    </div> 
            </div>
            </div>
            ) : (
                <div className="empty-cart">
                    <p>Ваша корзина пуста</p>
                </div>
            )}                
        </div>
    </div>
    </>
    )
    
}

export default CartList;