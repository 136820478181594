
const PrivacyPolicy =() =>{
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-terms'>
                    <h5>
                    Политика конфиденциальности
                    </h5>
                    <p className="text-uppercase">
                    1. ОБЩИЕ ПОЛОЖЕНИЯ
                    </p>
                    <p>
                    1.1. Настоящая Политика конфиденциальности персональных данных (далее «Политика конфиденциальности») действует в отношении всей информации, которую Интернет-сайт «Elena Alekseeva», расположенный по адресу www.elenaalekseeva.ru , может получить о Посетителе и/или о Пользователе и/или о Покупателе во время использования Интернет-сайта «Elena Alekseeva».
                    </p>
                    <p>
                    1.2. Использование Посетителем и/или Пользователем и/или Покупателем Интернет-сайта www.elenaalekseeva.ru , означает безоговорочное согласие Посетителя и/или Пользователя и/или Покупателя с настоящей Политикой конфиденциальности и указанными в ней условиями обработки персональной информации. В случае несогласия с настоящей Политикой конфиденциальности Посетитель и/или Пользователь и/или Покупатель должны незамедлительно прекратить использования Интернет-сайта www.elenaalekseeva.ru .
                    </p>
                    <p>
                    1.3. Настоящая Политика конфиденциальности применяется только к Интернет-сайту www.elenaalekseeva.ru . Интернет-сайт www.elenaalekseeva.ru не контролирует и не несет ответственность за сайты третьих лиц, на которые Посетитель и/или Пользователь и/или Покупатель могут перейти по ссылкам, доступным на Интернет-сайте www.elenaalekseeva.ru .
                    </p>
                    <p>
                    1.4. Администрация Интернет-сайта www.elenaalekseeva.ru не проверяет достоверность персональных данных, предоставляемых Посетителем и/или Пользователем Интернет-сайта www.elenaalekseeva.ru .
                    </p>
                    <p>
                    1.5. Настоящая Политика конфиденциальности вступает в силу в 2022 году.
                    </p>
                    <p className="text-uppercase">
                    2. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ
                    </p>
                    <p>
                    <b>Посетитель</b> – физическое лицо, пришедшее на Сайт без цели размещения Заказа.
                    </p>
                    <p>
                    <b>Пользователь</b> – полностью дееспособное физическое лицо, желающий разместить Заказ на Сайте, осуществляющий иные действия, выражающие его заинтересованность в Товарах Интернет-сайта «Elena Alekseeva» исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности
                    </p>
                    <p>
                    <b>Покупатель</b> – Пользователь Сайта, прошедший регистрацию на Сайте путем предоставления Продавцу индивидуальной информацию о себе (Фамилию, Имя, Отчество (при наличии), почтовый адрес, адрес электронный почты (e-mail), адрес доставки, банковские реквизиты, номер телефона), которая будет использована для размещения Заказа.
                    </p>
                    <p><b>Продавец</b> – НПД Елена Подлубная, осуществляющий продажу Товаров в соответствии с настоящими Правилами путем ознакомления Покупателя с описанием Товара с возможностью последующим оформлением Заказа.
                    </p>
                    <p><b>Администрация сайта</b> – уполномоченные сотрудники по управлению Сайтом, действующие от имени НПД Елены Подлубной, которые организуют и (или) осуществляет обработку персональных данных Посетителей и/или Пользователей и/или Покупателей, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                    </p>
                    <p><b>Сайт</b> – имеющий адрес в сети Интернет www.elenaalekseeva.ru, принадлежащий Продавцу. На нем представлены Товары, предлагаемые Продавцом Покупателям для оформления Заказов, а также условия оплаты и доставки этих Заказов Покупателям.
                    </p>
                    <p><b>Товар</b> – объект соглашения сторон, ювелирные изделия в ассортименте, представленные на Сайте.
                    </p>
                    <p><b>Заказ</b> – оформление сделки по покупке Товара, содержащая наименование, ассортимент, количество, стоимость и иные параметры выбранного Покупателем Товара с помощью Веб-интерфейса Сайта и отражается в личном кабинете Покупателя.</p>
                    <p><b>Персональные данные</b> - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
                    <p><b>Субъекты персональных данных</b> - физические лица (Посетитель и/или Пользователь и/или Покупатель).</p>
                    <p><b>Информация</b> – сведения (сообщения, данные) независимо от формы их представления.</p>
                    <p><b>Обработка персональных данных</b> - любое действие (операция) или совокупность действий (операций), совершаемые с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                    <p><b>Предоставление персональных данных</b> - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                    <p><b>Распространение персональных данных</b> - действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</p>
                    <p><b>Трансграничная передача персональных данных</b> - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
                    <p><b>Блокирование персональных данных</b> - временное прекращение обработки персональных данных (за исключением случаев, когда обработка необходима для уточнения персональных данных).</p>
                    <p><b>Уничтожение персональных данных</b> - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</p>
                    <p><b>Обезличивание персональных данных</b> - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
                    <p><b>Субъекты персональных данных</b> - физическое лицо, которое прямо или косвенно определено с помощью персональных данных.</p>
                    <p><b>Cookie (файлы cookie)</b> - это небольшие файлы с данными, которые создаются Сайтом, при его посещении Пользователем. Они направляются на устройство Пользователя (обычно в веб-браузер) во время использования Сайта и сохраняются на устройстве, чтобы затем повторно передаваться на Сайт при последующих посещениях пользователем Сайта.</p>
                    <p><b>IP-адрес</b> - уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>  
                    <p className="text-uppercase">
                    3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
                    </p>   
                    <p>3.1. Персональные данные обрабатываются Администрацией Сайта в соответствии с настоящей Политикой о конфиденциальности.
                    </p>     
                    <p>3.2. Администрация сайта обрабатывает следующие персональные данные:</p> 
                    <ul className='agreement-list ps-4'>
                            <li>Фамилию, Имя, Отчество (при наличии);</li>
                            <li>паспортные данные;</li>
                            <li>контактный номер телефона;</li>
                            <li>адрес электронной почты;</li>
                            <li>дату рождения;</li>
                            <li>адрес доставки Товара;</li>
                            <li>место жительства;</li>
                            <li>банковские реквизиты/реквизиты платежных документов;</li>
                            <li>сведения об оказанных услугах и/или приобретенных товарах;</li>
                            <li>IP Субъектов персональных данных;</li>
                            <li>данные о Заказах.</li>
                    </ul> 
                    <p>
                    3.3. Администрация Сайта не осуществляет обработку специальных категорий персональных данных, касающихся расовой и национальной принадлежности, политических взглядов, религиозных и философских убеждений, интимной жизни, состояния здоровья судимости физических лиц.
                    </p> 
                    <p className="text-uppercase">
                    4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ
                    </p>
                    <p>
                    4.1. Предоставляя свои персональные данные, субъект персональных данных соглашается на их обработку Администрацией сайта, в том числе в целях:</p>
                    <ul className='agreement-list ps-4'>
                            <li>выполнения Продавцом обязательств перед Покупателем по вопросу исполнения Заказа; </li>
                            <li>обработки запросов Пользователя и/или Покупателя;</li>
                            <li>сбора аналитических данных о посещениях Сайта, оптимизации Сайта;</li>
                            <li>проведения электронных опросов, анкетирования, рекламных и маркетинговых исследований (акций), в том числе касающихся услуг, оказываемых Продавцом и/или третьими лицами в интересах Продавца;</li>
                            <li>клиентской поддержки;</li>
                            <li>организации доставки Товара Покупателю, осуществляемого третьими лицами в интересах Продавца;</li>
                            <li>контроля удовлетворенности Покупателя, а также качества услуг, оказываемых Продавцом и/или третьими лицами в интересах Продавца;</li>
                            <li>получения оплаты за Товар.</li>
                    </ul>
                    <p>4.2. Администрация Сайта осуществляет обработку персональных данных на основе:</p>
                    <ul className='agreement-list ps-4'>
                            <li>определенных конкретных целей и способов обработки персональных данных;
                            </li>
                            <li>обеспечения надлежащей защиты персональных данных;</li>
                            <li>соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных;</li>
                            <li>соответствия объема, характера и способов обработки персональных данных целям обработки персональных данных;</li>
                            <li>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</li>
                            <li>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой;</li>
                            <li>хранения персональных данных в форме, позволяющей определить Субъекта персональных данных, не дольше, чем этого требуют цели их обработки</li>
                            <li>уничтожения или обезличивания персональных данных по достижении целей их обработки;</li>
                            <li>обеспечения конфиденциальности и безопасности обрабатываемых персональных данных.</li>

                    </ul>
                    <p className="text-uppercase">5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                    <p>5.1. Администрацией сайта осуществляется автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям.</p>
                    <p>5.2. Обработка и хранение персональных данных осуществляются не дольше, чем этого требуют цели обработки персональных данных.</p>
                    <p>5.3. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию при наступлении следующих условий:</p>
                    <ul className='agreement-list ps-4'>
                            <li>достижение целей обработки персональных данных или максимальных сроков хранения — в течение 30 дней;</li>
                            <li>утрата необходимости в достижении целей обработки персональных данных — в течение 30 дней;</li>
                            <li>предоставление субъектом персональных данных или его законным представителем подтверждения того, что персональные данные являются незаконно полученными или не являются необходимыми для заявленной цели обработки — в течение 7 дней;</li>
                            <li>невозможность обеспечения правомерности обработки персональных данных — в течение 10 дней;</li>
                            <li>отзыв субъектом персональных данных согласия на обработку персональных данных, если сохранение персональных данных более не требуется для целей обработки персональных данных — в течение 30 дней;</li>
                            <li>отзыв субъектом персональных данных согласия на использование персональных данных для контактов с потенциальными потребителями при продвижении товаров и услуг — в течение 2 дней;</li>
                    </ul>
                    <p className="text-uppercase">6. ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                    <p>6.1. Субъект персональных данных имеет право:</p>
                    <ul className='agreement-list ps-4'>
                            <li>на полную информацию об их персональных данных, обрабатываемых Администрацией сайта;</li>
                            <li>доступ к своим персональным данным, включая право на получение копии любой записи, содержащей их персональные данные;</li>
                            <li>уточнение своих персональных данных, их блокирование или уничтожение в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;</li>
                            <li>отзыв согласия на обработку персональных данных;</li>
                            <li>принятие мер по защите своих прав;</li>

                    </ul>
                    <p className="text-uppercase">7. ПРАВА АДМИНИСТРАЦИИ САЙТА</p>
                    <p>7.1. В рамках обработки персональных данных Администрация сайта имеет право:</p>
                    <ul className='agreement-list ps-4'>
                            <li>обрабатывать персональные данные Субъекта персональных данных в соответствии с заявленной целью;</li>
                            <li>требовать от Субъекта персональных данных предоставления достоверных персональных данных, необходимых для оформления Заказа и доставки Товара Покупателю;</li>
                            <li>идентификации Субъекта персональных данных;</li>
                            <li>отправлять Субъекту персональных данных информационные, в том числе рекламные сообщения, на электронную почту с его согласия, выраженного посредством совершения им действий, позволяющих достоверно установить его волеизъявление на получение сообщения. Сервисные сообщения, информирующие Субъекта персональных данных о Заказе и этапах его обработки, отправляются автоматически и не могут быть отклонены Субъектом персональных данных.</li>
                            <li>на трансграничную передачу персональных данных Субъекта персональных данных.</li>

                    </ul>
                    <p className="text-uppercase">8. ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
                    <p>8.1. Меры, необходимые для обеспечения безопасности персональных данных, включают:</p>
                    <ul className='agreement-list ps-4'>
                            <li>получение согласий Субъектов персональных данных на обработку их персональных данных;</li>
                            <li>обеспечение раздельного хранения персональных данных и их материальных носителей, обработка которых осуществляется в разных целях и которые содержат разные категории персональных данных;</li>
                            <li>установление запрета на передачу персональных данных по открытым каналам связи, вычислительным сетям вне пределов контроля Администрации сайта и сетям Интернет без применения установленных Администрацией сайта мер по обеспечению безопасности персональных данных (за исключением общедоступных и (или) обезличенных персональных данных);</li>
                            <li>хранение материальных носителей персональных данных с соблюдением условий, обеспечивающих сохранность персональных данных и исключающих несанкционированный доступ к ним.</li>
                    </ul>
                    <p>8.2. Принимая во внимание открытый характер Интернета, Администрация сайта не может гарантировать, что способ передачи информации между Администрацией сайта/Продавцом и Пользователем или информация, хранящаяся Сайте, полностью свободны от несанкционированного доступа третьих лиц, таких как хакеры, и Администрация сайта в рамках настоящей Политики конфиденциальности информирует об этом Субъектов персональных данных. Использование Субъектом Сайта демонстрирует безоговорочное принятие данного риска Субъектом персональных данных. В максимально возможной степени Администрация сайта/Продавец отказывается от любой ответственности за любые убытки и любой ущерб (включая, но не ограничиваясь, прямые, специальные, косвенные и любые иные), которые могут быть причинены Субъекту персональных данных в результате любой потери, несанкционированного доступа, неправильного использования или изменения любой информации, которую Субъект персональных данных отправляет на Сайт.</p>
                    <p className="text-uppercase">9. ИСПОЛЬЗОВАНИЕ ФАЙЛОВ «COOKIE» и АНАЛОГИЧНЫХ ТЕХНОЛОГИЙ</p>
                    <p>9.1. Администрация сайта использует файлы cookie и аналогичные технологии для эффективной работы Сайта, а также для сбора аналитических данных.</p>
                    <p>9.2. При посещении Субъекта персональных данных Сайта, помимо файлов cookie, создаваемых Сайтом, на устройстве Субъекта персональных данных могут сохраняться файлы cookie сторонних компаний (например, для осуществления сбора статистики Сайта, объявления или изображения или видео сторонних сайтов).</p>
                    <p>9.3. Информация, составляющая профиль (профилирующие и маркетинговые файлы cookie) могут передаваться третьим лицам, то есть организациям, участвующим в размещении файлов cookie на устройстве Субъекта персональных данных (например, плагины для социальных сетей). Также рекламные партнеры Продавца, которые действуют по поручению и на основании договоров с Продавцом и связаны его условиями, собирают информацию через профилирующие и маркетинговые cookie и обрабатывают ее в определенных Продавцом целях.</p>
                    <p>9.4. Некоторые страницы Сайта могут содержать электронные изображения, называемые «веб-маяками» (также известны как однопиксельные GIF-файлы, пустые GIF-файлы и пиксельные теги). Веб-маяки используются для упрощения размещения файлов сookie, предназначенных для осуществления сбора статистики Сайта. Веб-маяки позволяют в частности подсчитывать количество посетителей, просмотревших те или иные страницы Сайта. Также веб-маяки позволяют собирать статистические сведения о действиях и возможностях, которые больше всего интересуют Субъектов персональных данных на Сайте, что позволяет предоставлять Субъекту персональных данных более персонализированный контент (целевую рекламу). Веб-маяки не используются для сбора или доступа к персональным данным без согласия Субъекта персональных данных.</p>
                    <p>9.5. Субъект персональных данных вправе управлять параметрами браузера для блокирования или удаления файлов cookie путем настройки браузера устройства, выбрав иной вариант использования файлов cookie. В это этом случае, настройки браузера Субъекта персональных данных могут влиять на взаимодействие Сайтом (например, некоторые функции Сайта не будут доступны, если Субъект персональных данных отключит определенные файлы cookie).</p>
                    <p>9.6. Инструкции по блокированию или удалению файлов сookie можнополучить в политиках конфиденциальности или справочной документации браузеров:</p>
                    <ul className='agreement-list ps-4'>
                            <li>Internet Explorer support.microsoft.com/ru-ru/help/17442/windows- internet-explorer-delete-manage-cookies</li>
                            <li>Safari support.apple.com/ru-ru/HT201265</li>
                            <li>Chrome support.google.com/chrome/answer/95647?hl=ru</li>
                            <li>Firefox support.mozilla.org/ru/kb/udalenie-kukov-dlya-udaleniya-
informacii-kotoruyu-</li>
                            <li>Opera help.opera.com/Linux/10.50/ru/cookies.html</li>
                            <li>Яндекс yandex.ru/support/browser-classic/personal-data-protection/cookies.xml</li>
                    </ul>
                    <p className="text-uppercase">10. ПРОЧИЕ ПОЛОЖЕНИЯ</p>
                    <p>10.1. На Сайте могут быть размещены ссылки (включая без ограничений такие элементы как изображения, карты, звуковые файлы) на сторонние сайты и сервисы, которые Администрация сайта не контролирует и на которых действуют иные принципы работы с персональными данными, нежели определены настоящей Политикой конфиденциальности. В случае предоставления персональных данных любым подобным сайтам и сервисам их использование будет регламентироваться политиками, которые приняты на таких сайтах и сервисах. Администрация Сайта не несет ответственность за безопасность или конфиденциальность любой информации, собираемой сторонними сайтами или сервисами.</p>
                    <p>10.2. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Субекта персональных данных.</p>
                    <p>10.3. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
                    <p>10.4. По запросом, касающимся обработки персональных данных, необходимо направить письмо (предпочтительно с указанием темы обращения, например, «Запрос о персональных данных» либо «Запрос об изменении персональных данных» если Субъект персональных данных планирует изменить какие-то данные о себе либо «Отзыв согласия на обработку персональных данных» в случае отзыва согласия на обработку персональных данных) на адрес электронной почты elenaalekssseeva@gmail.com
                    </p>
                </div>    
            </div>            
        </div>

    )
}

export default PrivacyPolicy;