import { useState } from 'react';
import { Carousel } from 'react-bootstrap';

import './list-item-carousel.css';



const ListItemCarousel = (props)=> {

    const [isShown, setIsShown] = useState(false);
   
    return(
        
            <Carousel variant="dark" indicators={true} controls={isShown}
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
                onClick={()=> setIsShown(true)}> 
                                                               
                {   
                    props.images.map( image =>                      
                        <Carousel.Item className="wrapper-carousel" key={image.id}>
                                <div>
                                    <img  
                                    className="d-block w-100 img-crop"
                                    src={image.src}
                                    alt={image.alt}
                                    />
                                </div>  
                        </Carousel.Item>  
                    )
                }                                                                   
            </Carousel>
        
    )
}

export default ListItemCarousel;