import './burger.css';
import { NavLink } from 'react-router-dom';

import { useState } from 'react';

// import RightNav from "./right-nav";

const Burger =()=> {

    const [open, setOpen]= useState(false);
    return(       
    <>    
    <div className="mobile-nav-list "
        open={open}
        style={{transform: open ? 'translateX(0)' : 'translateX(-100%)'}}>
            <button type="button" className="mobile-close btn-close" aria-label="Close"
            open={open}
            onClick={() => setOpen(!open)}>
            </button>
            <ul onClick={() => setOpen(!open)}>
                <li><NavLink to="/">главная</NavLink></li>
                <li><NavLink to="catalog/shop-all">все товары</NavLink></li>
                <li><NavLink to="catalog/new">новинки</NavLink></li>
                <li><NavLink to="catalog/earings">серьги</NavLink></li>
                <li><NavLink to="catalog/necklaces">ожерелья</NavLink></li>
                <li><NavLink to="catalog/rings">кольца</NavLink></li>
                <li><NavLink to="catalog/brooch">броши</NavLink></li>
                <li><NavLink to="catalog/braslets">браслеты</NavLink></li>
                <li><NavLink to="catalog/accessories">аксессуары</NavLink></li>
                <li><NavLink to="catalog/sale">sale</NavLink></li>
                <li><NavLink to="/contact">контакты</NavLink></li>

            </ul>                
    </div>
    <div className='d-flex justify-content-start'>
        <div className="wrapper-burger"
        open={open} 
        onClick={() => setOpen(!open)}>       
                <>
                    <div className="burger-closed"/>
                    <div className="burger-closed"/>
                    <div className="burger-closed"/>
                </>           
        </div>
        <div 
            open={open}
            className={`${open ? "cover": ""}`}>
       </div>
    </div>        
    </>
                                                                   
    )
}

export default Burger;