
import {createContext, useState, useEffect} from 'react';

const CartContext = createContext();

const CartContextProvider = (props) => {
    const [arrItems, setArrItems] = useState([]); // все товары, которые сейчас в корзине
    // const [showItems, setShowItems] = useState(false); // содержимое корзины сейчас показывается?
    const [showAlert, setShowAlert] = useState(null); // сообщение после добавления в корзину
    const [showSpinner, setShowSpinner] = useState(false);

    const [itemsCost, _setItemsCost] = useState(null);
    const [deliveryCountry, _setDeliveryCountry] = useState('');
    const [deliveryCost, _setDeliveryCost] = useState(null);
    const [totalCost, _setTotalCost] = useState(null);

    useEffect(() => {
        //initialisation code nachalnoe sostoyanie
        let localCartObj;
        try {
            let localCart = localStorage.getItem("arrItems") || '[]';
            localCartObj = JSON.parse(localCart) || [];
        }catch(error){
            console.error(error);
        }finally {
            if(!Array.isArray(localCartObj)){
                localCartObj = [];
            }
            setArrItems(localCartObj);
            // updateTotals();
        }
      }, []);

    useEffect(() => {
        updateTotals();
    }, [arrItems, deliveryCountry]);

    useEffect(() => {
        if(showSpinner){
            setTimeout(() => {
                setShowSpinner(false);
            }, 700);
        }
    }, [showSpinner]);

    const append = (item, quantity = 1) => {
        // нужно проверить, нет ли уже такого товара в корзине
        const itemIndex = arrItems.findIndex(value => 
            value.id === item.id && (item.size ? item.size === value.size : true));
        if (itemIndex < 0) { // такого товара еще нет
            const newItem = {
                ...item,
                quantity: quantity
            };
            console.log('newItem', newItem);
            setArrItems([...arrItems, newItem]);
            let cartString = JSON.stringify([...arrItems, newItem]);
            localStorage.setItem("arrItems", cartString);
        } else { // такой товар уже есть
            const newItem = {
                ...arrItems[itemIndex],
                quantity: arrItems[itemIndex].quantity + quantity
            };
            console.log('size', newItem);
            const newCart = arrItems.slice(); // копия массива arrItems
            newCart.splice(itemIndex, 1, newItem);
            setArrItems(newCart);

            let stringCart = JSON.stringify(newCart);
            localStorage.setItem("arrItems", stringCart);
        }
        // updateTotals();
        setShowAlert("товар добавлен в корзину");
    };

    const setQuantity = (item, newQuantity) => {
        setShowSpinner(true);
         
        const itemIndex = arrItems.findIndex(value => 
            value.id === item.id && (item.size ? item.size === value.size : true));
        if (itemIndex >= 0) { // uzhe est
            newQuantity=Math.min(newQuantity,10);
            newQuantity=Math.max(newQuantity,1);
            const newItem = {
                ...arrItems[itemIndex],
                quantity: newQuantity
            };
            const newCart = arrItems.slice(); // копия массива arrItems
            newCart.splice(itemIndex, 1, newItem);
            setArrItems(newCart);

            let stringCart = JSON.stringify(newCart);
            localStorage.setItem("arrItems", stringCart);
            // updateTotals();              
            return newItem;
        }  
    }

    const remove = (item) => {
        setShowSpinner(true);
        
        const newCart = arrItems.filter(value => 
            !(item.id === value.id && (item.size ? item.size === value.size : true)));
        setArrItems(newCart);

        let stringCart = JSON.stringify(newCart);
        localStorage.setItem("arrItems", stringCart)
        // updateTotals();
    }

    const clearCart = () =>{
        setArrItems([]);
        let stringCart = JSON.stringify([]);
        localStorage.setItem("arrItems", stringCart)
    }

    const setDeliveryCountry = (countryCode) => {
        console.log('setDeliveryCountry, code =', countryCode);
        _setDeliveryCountry(countryCode);
        // updateTotals();
    }

    const updateTotals = () => {
        console.log('--> updateTotals ..., deliveryCountry =', deliveryCountry);
        const itemsCostLocal = arrItems?.reduce((sum, item) => sum + item.price * item.quantity, 0);
        let deliveryCostLocal = null;
        if (deliveryCountry ==='rus' ){
            deliveryCostLocal = itemsCostLocal >= 20000 ? 0 : 450;
        } else if (deliveryCountry === 'kaz' || deliveryCountry ==='bel'){
            deliveryCostLocal = itemsCostLocal >= 30000 ? 0 : 1500;
        }

        const totalCostLocal = itemsCostLocal + deliveryCostLocal || 0;
        _setItemsCost(itemsCostLocal);
        _setDeliveryCost(deliveryCostLocal);
        _setTotalCost(totalCostLocal);
    }

    const hideAlert = () => setShowAlert(null);

    const value = {
        items: arrItems,
        append: append,
        remove: remove,
        clearCart: clearCart,
        setQuantity: setQuantity,
        deliveryCountry,
        setDeliveryCountry: setDeliveryCountry,
        itemsCost,
        deliveryCost,
        totalCost,
        showAlert: showAlert,
        hideAlert: hideAlert,
        showSpinner: showSpinner,
        
    };

    return (
        <CartContext.Provider value={value}>
            {props.children}
        </CartContext.Provider>
    );
}

export {CartContext, CartContextProvider};