import { Component } from 'react';
import {NavLink} from 'react-router-dom';
import categoryMenu from '../categoryMenu';
import './side-navbar.css';
import "bootstrap-icons/font/bootstrap-icons.css";

export default class SideNavbar extends Component {
    state= {
        selectedMenu:  null,
    }
    render() {
        return(
            <div className="side-navbar col-xl-3 d-none d-xl-block ">
                <h6>Категории товаров</h6>
                <ul>
                    {
                    categoryMenu.map(item=>
                            
                        <li key={item.tagname}>
                            
                            <NavLink to={item.url}>
                             {this.props?.selectedMenu === item.tagname ? (
                                 <i className="bi bi-chevron-right pe-2 text-muted"
                                 style={{fontSize: '10px'}}></i>

                             ): (
                                ""
                             )}
                            {item.name}
                            </NavLink>
                        </li>
                    
                        )
                    }
               
                </ul>
            </div>
        )
    }
}
