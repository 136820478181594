import React from 'react';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { CartContext } from "../cart-context/cart-context";
import Spinner from '../spinner/spinner';
import ApiService from '../../services';

import './submit-form.css';

const SubmitForm = () => {
  const cart = useContext(CartContext);
  const navigate = useNavigate();

  const [codeValidation, setCodeValidation] = useState(false);
  const [disabledFields, setDisabledFields]= useState(false);

  const AutoSubmitToken = () => {
  const {values, submitForm} = useFormikContext();  
      useEffect( () => {
        if (cart.deliveryCountry !== values.country) {
          cart.setDeliveryCountry(values.country);    
        }
      }, [values, submitForm]);
    return null;
  }

  const openOrderDetails = data => {
    navigate(`/orders/${data?.order_key}`);
  };

  const showVerificationInput = data => {
      setCodeValidation(true);
      setDisabledFields(true);
  };

  // const handleChange =(e) =>{                  
    // console.log(e.target.value);
  // }

  const submitOrderForm = (values, { setSubmitting }) => {
    setSubmitting(true);
    const orderData ={
      line_items: cart.items.map((item) => {
        return {product_id: item.id, quantity: item.quantity}
      }),
      billing:{
        email: values.email,
        phone: values.phone,
        validationCode: values.validationCode,
        first_name: values.firstName,
        last_name: values.lastName ,
        address_1: values.address1,
        // address_2: values.address2,
        city: values.city,
        state: values.area,
        postcode: values.zip,
      },
      validation_code: codeValidation ? values.validationCode : undefined
    };

    ApiService.getInstance().createOrder(orderData)
    .then ((res) => {
        setSubmitting(false);
        switch(res.data?.status + '') {
            case '200': openOrderDetails(res.data);
            cart.clearCart();
            break;

            case '401': showVerificationInput(res.data);
            break;
        }
    })
    .catch((error) =>{
      setSubmitting(false);
      console.log('res status:', error.res.status);
      console.log('res data:', error.res.data);
    });

    return orderData;
  }

    return (
    <Formik
      initialValues={{ country: 'null', firstName: '', lastName: '', companyName: '', email: '', 
      phone: '', validationCode: '', address1: '', city: '', area: '', zip: '', acceptedTerms: false }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Обязательное поле'),
        lastName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Обязательное поле'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Обязательное поле'),
        phone: Yup.string()
          .required('Обязательное поле'),
        // validationCode: Yup.string()
        //   .required('Обязательное поле'),
        country: Yup.string()
        .required('Обязательное поле')
        .oneOf(['rus', 'bel', 'kaz'], 'Обязательное поле'),
        address1: Yup.string()
          .required('Обязательное поле'),     
        city: Yup.string()
          .required('Обязательное поле'),
        area: Yup.string()
          .required('Обязательное поле'),
        zip: Yup.string()
          .required('Обязательное поле'),
        acceptedTerms: Yup.boolean()
          .oneOf([true], 'Необходимо Ваше согласие на обработку персональных данных'),
      })}
    
      onSubmit={(values, helpers) => {setTimeout(() => {
          submitOrderForm(values, helpers);
        }, 1);
      }}
    >
        {({ isSubmitting, touched, errors }) => (
        <Form>
          
            <div className="row ">
            {isSubmitting && <Spinner/>}
                <div className='col-12 mb-2 px-1'>
                  <label htmlFor="country" className="form-label">Страна</label>
                  <Field as="select" name="country"  
                  className= {`form-select py-2 ${touched.country && errors.country ? "is-invalid" : ""}`}
                    disabled={disabledFields}>
                        <option value="null">Выберите страну из списка</option>
                        <option value="rus">Российская Федерация</option>
                        <option value="bel">Республика Беларусь</option>
                        <option value="kaz">Казахстан</option>
                  </Field>
                  <ErrorMessage component="div" name="country" className="invalid-feedback" />
                </div>
            
                <div className="col-md-6 form-group mb-2 px-1">
                    <label htmlFor="firstName" className="form-label">Имя</label>
                    <Field name="firstName" type="text" 
                    className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} 
                    disabled={disabledFields}/>
                    <ErrorMessage component="div" name="firstName" className="invalid-feedback"/>
                </div>
                <div className="col-md-6 form-group mb-2 px-1">
                    <label htmlFor="lastName" className="form-label">Фамилия</label>
                    <Field name="lastName" type="text" 
                    className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`}   
                    disabled={disabledFields}/>
                    <ErrorMessage component="div" name="lastName" className="invalid-feedback" />    
                </div>
                <div className="col-12 mb-2 form-group px-1">
                    <label htmlFor="companyName" className="form-label">Название компании(опционально)</label>
                    <Field name="companyName" type="text" className="form-control"
                    disabled={disabledFields}/>  
                </div>
                <div className="col-md-6 mb-2 form-group px-1">
                    <label htmlFor="email" className="form-label">Email</label>
                    <Field name="email" type="email" 
                    className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} 
                    disabled={disabledFields}/>                  
                    <ErrorMessage component="div" name="email" className="invalid-feedback"/>                                  
                </div>
                <div className="col-md-6 mb-2 form-group px-1">
                    <label htmlFor="phone" className="form-label">Телефон</label>
                    <Field name="phone" type="phone" 
                    className={`form-control ${touched.phone && errors.phone ?"is-invalid" : ""}`} 
                    disabled={disabledFields}/>
                    <ErrorMessage component="div" name="phone" className="invalid-feedback" />    
                </div>

                {codeValidation && 
                  <>          
                  <div className='row '>
                    <div className='validation-code'>
                      <div className="validation-code-message mb-2">
                        Мы выслали на вашу электронную почту код верификации.<br/> 
                        Пожалуйста, введите его для перехода к оплате.<br/>
                        Письмо могло попасть в папку "спам".
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="col-7 pe-4 ">
                            <label htmlFor="validationCode" className="visually-hidden"></label>
                            <Field autoFocus name="validationCode" type="validationCode" 
                            className={`form-control ${touched.phone && errors.phone ?"is-invalid" : ""}`}/>
                            <ErrorMessage component="div" name="validationCode" className="invalid-feedback"/> 
                            
                        </div>
                        <div className="validation-code-btn col-5 align-items-end">
                            <button type="submit" className="btn w-100" >Подтвердить</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                }
                
                <div className="col-12 mb-2 form-group px-1">
                    <label htmlFor="address1" className="form-label">Улица, Дом, Строение, Квартира</label>
                    <Field name="address1" type="text" 
                    className={`form-control ${touched.address1 && errors.address1 ? "is-invalid" : ""}`} 
                    disabled={disabledFields}/>  
                    <ErrorMessage component="div" name="address1" className="invalid-feedback" /> 

                </div>
                
                <div className="col-12 mb-2 form-group px-1">
                    <label htmlFor="area" className="form-label">Край, Область, Регион</label>
                    <Field name="area" type="text" 
                    className= {`form-control ${touched.area && errors.area ? "is-invalid" : ""}`}
                    disabled={disabledFields}/> 
                    <ErrorMessage component="div" name="area" className="invalid-feedback" />
                </div>
                <div className="col-12 col-md-8 mb-2 form-group px-1">
                    <label htmlFor="city" className="form-label">Город</label>
                    <Field name="city" type="text" 
                    className= {`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                    disabled={disabledFields}/> 
                    <ErrorMessage component="div" name="city" className="invalid-feedback" />
                </div>
                
                <div className="col-12 col-md-4 mb-2 form-group px-1">
                    <label htmlFor="zip" className="form-label">Индекс</label>
                    <Field name="zip" type="text" 
                    className= {`form-control ${touched.zip && errors.zip ? "is-invalid" : ""}`}
                    disabled={disabledFields}/>
                    <ErrorMessage component="div" name="zip" className="invalid-feedback" /> 
                </div>
                <div className="col-12">
                    <div className="form-check mb-4 mt-3">
                        <Field name="acceptedTerms" 
                        className= {`form-check-input ${touched.acceptedTerms && errors.acceptedTerms ? "is-invalid" : ""}`} 
                        type="checkbox" id="gridCheck"/>                     
                        <label className="form-check-label ps-3" htmlFor="acceptedTerms">
                        <Link to='/agreement'> Даю согласие на обработку моих персональных данных</Link>
                           
                        </label>                      
                        <ErrorMessage component="div" name="acceptedTerms" className="invalid-feedback"/>
                    </div>
                </div>
                <div className="col-12">
                    <button type="submit" className="btn btn-dark w-50">Перейти к оплате</button>
                </div>
            </div>
            <AutoSubmitToken />
        </Form>
        )}      
    </Formik>
  );
};

export default SubmitForm;
