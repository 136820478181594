import {useContext} from 'react';
import { CartContext } from '../cart-context/cart-context';

import './spinner-cart.css';

export default function SpinnerCart () {
    const cart = useContext(CartContext);

  
    return (
        <div className='loader-wrapper-cart'>
            <div className="loader-cart">{cart.showSpinner}</div>
        </div>
        
    );
}