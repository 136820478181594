import './about-us.css';

const AboutUs =()=>{
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-about-us'>
                Elena Alekseeva - дизайнер модной бижутерии. <br/>
                Уже более 15-ти лет дизайнер занимается разработкой и созданием украшений ручной работы. Её творчество высоко ценят по всему миру. Интуитивно подмечая и анализируя модные тенденции, вносит их в свои коллекции каждый сезон. Участник Mercedes-Benz Fashion Week в Москве, победитель в конкурсах для молодых дизайнеров. <br/>
                Создавая свои украшения, она сотрудничает с известными русскими дизайнерами. <br/>
                Отличительной особенностью бижутерии Alekseeva являются эксклюзивность - каждое украшение лишь в нескольких экземплярах, узнаваемые формы, живость и сочность красок, ослепительное сияние кристаллов и высокое качество.                              
                </div>    
            </div>            
        </div>
    )
}

export default AboutUs;