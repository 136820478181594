import ContentLoader from 'react-content-loader'

const SkeletonItemCarousel =()=> {
    return(
        <ContentLoader width="100%" viewBox="0 0 1200 1250">
            <rect x="0" y="20" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
    )
}

export default SkeletonItemCarousel;