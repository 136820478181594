import './return-exchange-terms.css';

const ReturnExchangeTerms =() => {
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-terms'>
                    <h5>
                    Возврат и обмен
                    </h5>
                    <div>
                        <p className='text-uppercase mb-2'>
                        ВНИМАНИЕ:
                        </p>
                        <p>
                        Изделия <span className='text-uppercase'>НАДЛЕЖАЩЕГО</span> качества <span className='text-uppercase'>НЕ</span> подлежат возврату или обмену на
                        аналогичный товар других размеров, формы, габарита, фасона, расцветки
                        или комплектации.
                        </p>
                        <p>
                        Если Вы получили украшение ненадлежащего качества, направьте письмо с
                        фотографиями украшения и описанием проблемы на
                        elenaalekssseeva@gmail.com.<br/>
                        Гарантийный срок - 2 недели с момента
                        получения. В ответном письме мы сообщим, как Вы сможете заменить
                        приобретенное украшение наиболее удобным и быстрым для Вас способом.
                        Расходы на доставку украшения в данном случае мы возьмем на себя. Либо
                        вернем деньги.
                        </p>
                        <p>
                        *мы вернем деньги в течение 10-ти дней после получения украшений.
                        </p>            
                    </div>    
                </div>            
            </div>
        </div>
    )

}

export default ReturnExchangeTerms