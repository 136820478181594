

const DeliveryPaymentTerms =() =>{
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-terms'>
                    <h5>
                    Оплата и доставка
                    </h5>
                    <div>
                    <p>
                    После оформления заказа на сайте Вы получите письмо с подтверждением
                    на Вашу электронную почту.
                    </p>
                    <p>
                    После проведения оплаты на Вашу электронную почту будет отправлен чек
                    о покупке.
                    </p>
                    <p>
                    Если оплаченный Вами товар в наличии, отправка Вашего заказа
                    произойдет в течение 1-3 рабочих дней.
                    </p>
                    <p>
                    Максимальная сумма заказа в интернет-магазине возможна на сумму 100
                    000 рублей.
                    </p>
                    <p>
                    Оплата Предзаказа осуществляется сразу во время оформления.
                    Более подробная информация в разделе &quot;Предзаказ&quot;
                    </p>
                    <p>
                    Доставка по России, РБ и Казахстану осуществляется курьерской
                    службой СДЭК<br/>
                    - До пункта самовывоза <br/>
                    - Курьером дверь-дверь 
                    </p>
                    <p>
                    После отправки заказа, на Вашу почту будет выслан трек-номер для
                    отслеживания
                    </p>
                </div>
                
            </div>            
        </div>
    </div>
    )
}

export default DeliveryPaymentTerms;