
import {Tabs, Tab } from 'react-bootstrap';

import './list-item-tabs.css';

const ListItemTabs =(props) =>{


    return(
        <Tabs defaultActiveKey="description" id="uncontrolled-tab-example" className="my-4 rounded-0">
            <Tab eventKey="description" title="описание">
                <div className="descr-item mb-3">
                    <div dangerouslySetInnerHTML={{__html: props.description}}></div>
                </div>
            </Tab>
            <Tab eventKey="profile" title="доставка и возврат" >
            <div>
                        <p>
                        Доставка
                        </p>
                        <p>
                        Если оплаченный Вами товар в наличии, отправка Вашего заказа
                        произойдет в течение 1-3 рабочих дней.<br/>
                        Оплата Предзаказа осуществляется сразу во время оформления.<br/>
                        Более подробная информация в разделе &quot;Предзаказ&quot;<br/>
                        Доставка по России, РБ и Казахстану осуществляется курьерской
                        службой СДЭК<br/>
                        - До пункта самовывоза <br/>
                        - Курьером дверь-дверь 
                        </p>

                    </div> 
                    <div>
                        <p>
                        Возврат и обмен
                        </p>
                        <p>
                        Изделия <span className='text-uppercase'>НАДЛЕЖАЩЕГО</span> качества <span className='text-uppercase'>НЕ</span> подлежат возврату или обмену на
                        аналогичный товар других размеров, формы, габарита, фасона, расцветки
                        или комплектации.<br/>
                        Если Вы получили украшение ненадлежащего качества, направьте письмо с
                        фотографиями украшения и описанием проблемы на
                        elenaalekssseeva@gmail.com.<br/>
                        Гарантийный срок - 2 недели с момента
                        получения. В ответном письме мы сообщим, как Вы сможете заменить
                        приобретенное украшение наиболее удобным и быстрым для Вас способом.
                        Расходы на доставку украшения в данном случае мы возьмем на себя. Либо
                        вернем деньги.<br/>
                        *мы вернем деньги в течение 10-ти дней после получения украшений.
                        </p>           
                    </div>                        
            </Tab>
            <Tab eventKey="contact" title="уход за украшениями">
                <div>
                    <p>
                    Основные материалы, из которых мы чаще всего делаем бижутерию - это сплавы: брасс, медь, нержавеющая сталь. Некоторые из них склонны к коррозии при неправильной эксплуатации – они быстро темнеют.Чтобы этого не произошло, и ваши украшения прослужили вам как можно дольше, стоит следовать нескольким простым правилам:
                    </p>
                    <p>
                    Ухаживайте за украшениями с использованием специальных средств;
                    </p>
                    <p>
                    Украшения из ювелирного сплава боятся воды (особенно солёной), крема и парфюма — снимайте их перед душем и нанесением косметики;
                    </p>
                    <p>
                    К сожалению, на жизнь бижутерии влияет и солнце – при реакции материал может начать разрушаться. Поэтому на жарком побережье украшения лучше снимать.
                    </p>
                </div>                                                 
            </Tab>
        </Tabs>

    )
}

export default ListItemTabs;