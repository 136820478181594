
import './spinner.css';

export default function Spinner () {

    return (
        <div className='loader-wrapper'>
            <div className="loader"></div>
        </div>
        
    );
}