import { NavLink, Link } from 'react-router-dom';
import Burger from './burger';
import SearchComponent from '../search-component/search-component';
import ShowAlert from "../show-alert/show-alert";
import CartIcon from "../cart-icon/cart-icon";
import { useContext, useState } from "react";
import { CartContext } from "../cart-context/cart-context";
import './navbar.css';
import HeaderLogo from '../header-logo/header-logo';
import logo from '../header-logo/LOGO-rev.jpg';



const Navbar =()=> {
    const cart = useContext(CartContext);
    const[visible, setVisible] =useState(false);

    const handleSearchMobile =() =>{
        setVisible(!visible);
    }

    return(
        <>  
            <div className="row">
                <div className='d-none d-lg-block nnavbar px-0'>
                    <HeaderLogo/>
                    <div style={{backgroundColor:'#414344'}} className='pos-rel'>
                        {cart.showAlert && <ShowAlert />}
                        <div className='container wrapper-content px-3'>                                            
                                <ul>
                                    <li><NavLink to="/">главная</NavLink></li>
                                    <li><NavLink to="/catalog/shop-all">все товары</NavLink></li>
                                    <li><NavLink to="/catalog/earings">серьги</NavLink></li>
                                    <li><NavLink to="/catalog/necklaces">ожерелья</NavLink></li>
                                    <li><NavLink to="/catalog/rings">кольца</NavLink></li>
                                    <li><NavLink to="/catalog/brooch">броши</NavLink></li>
                                    <li><NavLink to="/catalog/braslets">браслеты</NavLink></li>
                                    <li><NavLink to="/catalog/accessories">аксессуары</NavLink></li>
                                    <li><NavLink to="/catalog/sale">sale</NavLink></li>
                                    <li><NavLink to="/contact">контакты</NavLink></li>
                                </ul>                            
                        </div>   
                    </div>                             
                </div>
            </div>        
            <div className="row ">            
                <div className="d-lg-none wrapper-mobile-nav ">
                    {cart.showAlert && <ShowAlert />}                
                    <div className='container pos-rel px-2'>
                        <div className="col-12 ">
                            <div className="row me-1 " >
                                <div className='col-3 d-flex justify-content-start'>
                                    <Burger/>
                                </div >
                                <div className='col-6 d-flex justify-content-center ps-4'>
                                <Link to="/">
                                    <img src={logo} alt="logo" className="app-logo"/>
                                </Link>                                
                                </div>                                            
                                <div className='col-3 d-flex align-items-center justify-content-between'>
                                    <div className='search-mobile'>
                                        <button 
                                        onClick={handleSearchMobile}>
                                            {!visible && <><i className="bi bi-search pe-4 " style={{fontSize:"20px", color:'black'}}></i></>}                               
                                            {visible && <><i className="bi bi-x-lg" style={{color:'black'}}></i></>}                                    
                                        </button>    
                                    </div>
                                    <CartIcon/>
                                </div>
                                <div className='wrapper-search-mobile'>
                                    {visible && <SearchComponent/>}
                                </div>                            
                            </div>
                        </div>                                        
                    </div>
                </div>
            
            </div>        
        </>
        
    )

    
}

export default Navbar;