import ContentLoader from 'react-content-loader'

const SkeletonItem =()=> {
    return(
        <ContentLoader width="100%" viewBox="0 0 1500 1200">
            <rect x="0" y="0" rx="0" ry="0" width="80%" height="80" />
            <rect x="0" y="150" rx="0" ry="0" width="250" height="40" />
            <rect x="0" y="250" rx="0" ry="0" width="380" height="60" />
            <rect x="0" y="480" rx="0" ry="0" width="250" height="40" />
            <rect x="0" y="550" rx="0" ry="0" width="580" height="40" />
            <rect x="0" y="750" rx="0" ry="0" width="250" height="40" />
            <rect x="0" y="950" rx="0" ry="0" width="750" height="200" />
          
        </ContentLoader>

    )
    
}

export default SkeletonItem;