import './scroll-button.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import React, {useState} from 'react'; 


const ScrollButton = () =>{ 

const [visible, setVisible] = useState(false) 

const toggleVisible = () => { 
	const scrolled = document.documentElement.scrollTop; 
	if (scrolled > 700){ 
	setVisible(true) 
	} 
	else if (scrolled <= 700){ 
	setVisible(false) 
	} 
}; 

const scrollToTop = () =>{ 
	window.scrollTo({ 
	top: 0, 
	behavior: 'smooth'
	}); 
}; 

window.addEventListener('scroll', toggleVisible); 

return ( 
    
        <div className='content-scroll-button'>
            <button className='scroll-up'
            style={{display: visible ? 'block' : 'none'}}
            onClick={scrollToTop} 
            ><i className="bi bi-arrow-up" style={{padding: 10}}></i></button>
    </div>
	
); 
} 

export default ScrollButton; 
