import {useEffect, useContext} from 'react';
import { CartContext } from '../cart-context/cart-context';

import './show-alert.css';

export default function ShowAlert() {
    const cart = useContext(CartContext);

    useEffect(() => {
        const timeoutId = setTimeout(cart.hideAlert, 2000);
        
        return () => clearTimeout(timeoutId);
    }, [cart.hideAlert]);

    return (
        <div className="show-alert d-flex justify-content-center align-items-center">{cart.showAlert}</div>
    );
}