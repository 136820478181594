import {Link} from 'react-router-dom';
import './order-id.css';

import thumb1 from './thumb2.png';
import thumb2 from './thumb1.jpg';
import thumb3 from './thumb3.jpg';

const OrderId =() =>{

    return(

        <div className='row wrapper-order-history-id '>
            <div className="col-12 col-md-5 d-flex flex-column justify-content-start">
                <div className=''>
                    Заказ №
                </div>
                <div>
                    Стоимость
                </div>
                <div>
                    Дата заказа 
                </div>
                <div className='d-none d-md-block'>
                    <Link to='/orders/:orderKey'>
                        <button className=' btn btn-dark'>
                            Подробнее
                        </button>

                    </Link>

                    
                </div>
            </div>
            {/* Ne bolee 3 items show in row */}
            <div className='col-12 col-md-7  d-flex flex-wrap justify-content-start'>
                <div className='order-history-thumb-item'>
                    <img src={thumb2} alt="thumb2"></img>
                </div>
                <div className='order-history-thumb-item'>
                    <img src={thumb1} alt="thumb2"></img>
                </div>
                <div className='order-history-thumb-item'>
                    <img src={thumb3} alt="thumb2"></img>
                </div>


            </div>
            <div className='d-md-none'>
                <Link to="/orderHistoryIdDetails">
                    <button className=' btn btn-dark'>
                        Детали заказа
                    </button>
                </Link>
                    
            </div>



        </div>

        

    )
}

export default OrderId;