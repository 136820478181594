import { Link } from 'react-router-dom';

import './shop-list.css';
import earingCard from './earing-card.jpeg';
import necklaceCard from './necklace-card.jpeg';
import brasletCard from './braslet-card.jpeg';
import broochCard from './brooch-card.jpeg';
import ringCard from './ring-card.jpeg';
import accessoriesCard from './accessories-card.jpeg';


const ShopList = () => {
    return (
        <div>
            <section className="wrapper-main-banner">
                <div className='undernav'></div>
                <div className="img-main-page">            
                    <div className="container">
                        <div className="row">
                            <div className="wrapper-main-title d-flex">
                                <div className="col-sm-8 col-lg-6 d-flex flex-column mt-auto mb-0">
                                    <div className='d-flex justify-content-center'>
                                        <h1 className='main-title'>
                                            миллиард
                                            кристаллов
                                        </h1>
                                    </div>
                                    <div className='shop-new d-none d-md-flex justify-content-center '>
                                        <Link to="/catalog/new">
                                            <button className="btn btn-lg ">                                           
                                                    каталог с новинками 
                                            </button>
                                        </Link>
                                    </div>                                
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>        
            </section>
            <section>
                <div className="container shop-list-wrapper">            
                    <div className="row">  
                        <div className="shop-list-title">
                            <div className='shop-new d-md-none justify-content-center mb-4'>
                                <div className='shop-new-title'>Наша новая коллекция уже в продаже</div>
                                <Link to="/catalog/new">
                                    <button className="btn btn-dark btn-lg ">                            
                                    каталог с новинками
                                    </button>
                                </Link>
                            </div>
                                <h3>товары по категориям</h3>
                        </div>                 
                        <div className="col-md-4 col-6 d-flex  px-2">    
                        <Link to="/catalog/earings" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">                            
                                <img src={earingCard} alt="earings" className="img-cards"/>
                                <div className="lists-title">
                                серьги                              
                                </div>
                            </div> 
                        </Link>                
                        </div>
                        <div className="col-md-4 col-6 d-flex  px-2">
                        <Link to="/catalog/necklaces" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">
                                <img src={necklaceCard} alt="necklaces" className="img-cards"/>
                                <div className="lists-title">
                                ожерелья
                                </div>
                            </div>
                        </Link>
                        </div>
                        <div className="col-md-4 col-6 d-flex  px-2">   
                        <Link to="/catalog/rings" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">
                                <img src={ringCard} alt="rings" className="img-cards"/>
                                <div className="lists-title">
                                кольца
                                </div>
                            </div>
                        </Link>                     
                            
                        </div>
                        <div className="col-md-4 col-6 d-flex  px-2">  
                        <Link to="/catalog/braslets" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">
                                <img src={brasletCard} alt="bracelets" className="img-cards"/>
                                <div className="lists-title">
                                браслеты
                                </div>
                            </div>
                        </Link>                                            
                        </div>
                        <div className="col-md-4 col-6 d-flex  px-2">
                        <Link to="/catalog/brooch" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">
                                <img src={broochCard} alt="brooch" className="img-cards"/>
                                <div className="lists-title">
                                броши
                                </div>
                            </div>
                        </Link>
                            
                        </div>
                        <div className="col-md-4 col-6 d-flex  px-2">
                        <Link to="/catalog/accessories" className="w-100">
                            <div className="wrapper-cards d-flex justify-content-center w-100">
                                <img src={accessoriesCard} alt="altro" className="img-cards"/>
                                <div className="lists-title">
                                аксессуары
                                </div>
                            </div>   
                        </Link>                                         
                        </div>
                    </div>
                </div>

            </section>
            
        </div>

    )
}

export default ShopList;