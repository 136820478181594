import ContentLoader from 'react-content-loader'

const SkeletonBanner =()=> {
    return(
        <ContentLoader width="100%" viewBox="0 0 1500 700">
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="540" />
            <rect x="0" y="600" rx="0" ry="0" width="350" height="40" />
          
        </ContentLoader>

    )
    
}

export default SkeletonBanner;