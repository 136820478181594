import './search-component-result.css';

const SearchComponentResult = (props) =>{
    return(
        <div className='search-result'>
            {props.searchString}
        </div>

    )
}

export default SearchComponentResult;