import ContentLoader from 'react-content-loader'

const SkeletonCard =()=> {
    return(
        <ContentLoader width="100%" viewBox="0 0 700 910">
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="680" />
        <rect x="0" y="730" rx="0" ry="0" width="100%" height="40" />
        <rect x="200" y="820" rx="0" ry="0" width="300" height="40" />
      

        </ContentLoader>

    )
}

export default SkeletonCard;