import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import './search-component-preview.css';
import earing1 from './earing1.png';
import earing2 from './earing2.jpg';
import braslet1 from './braslet1.jpg';



const SearchComponentPreview =()=>{

    return(
        <div className="search-result-preview">
           
                <div className="row wrapper-search-preview">
                    <div className='search-header text-muted'>
                    популярные товары:
                    </div>
                    
                    <div className="col-4 d-flex flex-column px-2">
                        <div className="thumb-search-list d-flex justify-content-center ">
                            <img className='w-100' src={earing1} alt="earing1"></img>
                            
                        </div>
                        <div className="search-preview-name pb-1">
                            Black Swarovski crystals, Gold tone earrings

                        </div>
                        <div className="search-preview-price">
                                4800 <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/>

                        </div>
                    
                    </div>
                    <div className="col-4 d-flex flex-column px-2">
                            <div className="thumb-search-list d-flex justify-content-center">
                                <img className='w-100' src={earing2} alt="earing1"></img>
                                
                            </div>
                            <div className="search-preview-name pb-1">
                                Multicolor Swarovski crystal, 24k gold plated necklace

                            </div>
                            <div className="search-preview-price">
                                4400 <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/>

                            </div>

                            
                            
                    </div>
                    <div className="col-4 d-flex flex-column px-2">
                        <div className="thumb-search-list d-flex justify-content-center">
                            <img className='w-100'  src={braslet1} alt="earing1"></img>
                            

                        </div>
                        <div className="search-preview-name pb-1">
                                Name here

                        </div>
                        <div className="search-preview-price">
                            3800 <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/>

                        </div>
                        
                            
                    </div>
                        
                </div>
            </div>
            
       


                

    )
}

export default SearchComponentPreview;