import { Accordion } from "react-bootstrap";
import {useContext} from 'react';
import { CartContext } from "../cart-context/cart-context";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'

import SubmitForm from "../submit-form/submit-form";
import OrderProcessingCart from "../order-processing-cart/order-processing-cart";

import './order-processing.css';

const OrderProcessing =() => {
    const cart = useContext(CartContext);
    

    return (
        <div className="container wrapper-content mb-5">
            <div className="row ">
                <div className="undernav"></div>
                <h3 className="order-processing-header">
                Оформление заказа
                </h3>
                <div className=" col-12 d-lg-none ">
                    <div className="accordion-small px-0">
                        <Accordion >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="item-details-bold-sm "> Ваш заказ </div>
                                        <div className="item-details-bold-sm  pe-2"> {cart.totalCost} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>    
                                    <OrderProcessingCart/>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className="col-12 col-lg-7">
                    <div className=" border my-3 px-3 py-3">
                        <SubmitForm/>
                    </div>
                </div>
                <div className="col-lg-5 d-none d-lg-block border  my-3 py-3 wrapping-light">
                    <div className="">
                        <div className="row">
                            <OrderProcessingCart/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderProcessing;
