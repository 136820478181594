import {useParams, Link} from 'react-router-dom';
import { useState, useEffect } from 'react';
import ApiService from '../../services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import './order-id-details.css';
import Spinner from '../spinner/spinner';


const OrderIdDetails =() =>{

    const {orderKey} = useParams();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    const loadData = async () => {
        ApiService.getInstance().getOrderDetails(orderKey)
          .then(res => {
              const details = res.data;
              setData(details || {});
              console.log(details);
              setLoading(false);  
        })
    };
    
    useEffect(() => {
        setLoading(true);
        loadData();
        return () => {};
    }, []);

    const date = data?.date_created?.length && new Date(data.date_created);
    
    return(
        <div className="order-history">
            <div>
                <div className="under-fix-nav">&nbsp;</div>
            </div>

            <div className="container wrapper-content">
                {loading && <Spinner/>}
                <div className="row">
                    <div className="col-12 ">
                            {
                                data?.billing ? (
                                    <h4 className="header-order-history">
                                    Заказ № {data?.id } от {date?.toLocaleDateString()}
                                </h4>
                                ) : (<h4 className="header-order-history"></h4>)
                            }
                       
                        <div className="wrapper-order-history-details">
                            <div className='row'>
                                <div className="col-12 col-md-6 d-flex flex-column justify-content-start border-end">
                                    <div className='order-history-details '>
                                        <div className='order-history-delivery-details'>
                                            {/* <div>
                                                DHL tracking number:
                                            </div> */}
                                            { data?.billing ? 
                                                (<h6 style={{fontSize: 14, marginBottom: 14}}> Данные доставки </h6>) :
                                                (<h6> Загрузка данных вашего заказа... </h6>)
                                            }
                                            <div>
                                            {data?.billing?.first_name} {data?.billing?.last_name}
                                            </div>
                                            <div>
                                                {data?.billing?.address_1}
                                            </div>
                                            <div>
                                                {/* address line 2 */}
                                            </div>
                                            <div>
                                                {data?.billing?.postcode}
                                            </div>
                                            <div>
                                                {data?.billing?.city} 
                                            </div>
                                            <div>
                                                {data?.billing?.country}
                                            </div>
                                            <div>
                                               {data?.billing?.email}
                                            </div>
                                            {
                                                    data?.billing ?(
                                                        <div>
                                                            Телефон: {data?.billing?.phone}
                                                        </div>
                                                    ) : ('')
                                                }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-column px-0">
                                        <div className='order-history-details'>
                                            <div className='costs-info'>
                                                {  
                                                    data?.items?.map((item, index) =>
                                                    <div className=" d-flex mb-3" key={index}>
                                                        <div className="col-4 col-md-3 order-history-thumb">
                                                            <img src={item?.product?.images?.[0]?.src} alt="earings"></img>
                                                        </div>
                                                        <div className="col-8 col-md-9 d-flex flex-column">
                                                            <div className="d-flex ps-3">
                                                                <div className="item-details-bold-sm">
                                                                <Link to=''>
                                                                <span>{item?.name} </span> 
                                                                </Link>                
                                                                </div>                                       
                                                            </div>
                                                            <div className="item-details-sm ps-3">
                                                                { item?.meta_data && Object.keys(item.meta_data).map(key => 
                                                                <div key={'a_'+key}>{item.meta_data[key].display_key}: {item.meta_data[key].value}</div>
                                                                )}
                                                            </div>
                                                            <div className="item-details-sm ps-3">
                                                                <span>{item?.quantity} шт</span>
                                                            </div>
                                                            <div className="item-details-sm ps-3">
                                                                <span>{item?.total} <FontAwesomeIcon icon={solid('ruble-sign')} size="xs"/> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            </div>
                                            
                                            <div className=' d-flex flex-column'>
                                                <div className='col-6 offset-5 mt-3'>
                                                    {
                                                        data?.billing ? (
                                                            <div className='totals-sm'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>Всего:</span>
                                                                    <span>{data?.subtotal} <FontAwesomeIcon icon={solid('ruble-sign')} size="xs"/> </span>
                                                                </div>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>Доставка:</span>
                                                                    <span>{+data?.shipping_total} <FontAwesomeIcon icon={solid('ruble-sign')} size="xs"/> </span>
                                                                </div>
                                                                <div className='d-flex justify-content-between'>
                                                                    <span>Итого:</span>
                                                                    <span>{+data?.total} <FontAwesomeIcon icon={solid('ruble-sign')} size="xs"/> </span>
                                                                </div>
                                                            </div>
                                                        ) : ('')
                                                    }
                                                </div>
                                            </div>
                                        </div>                                       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderIdDetails;