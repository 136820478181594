import { useContext } from "react";
import {Link} from 'react-router-dom';
import  {CartContext} from "../cart-context/cart-context";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro' 

import './cart-list-item.css';

export default function CartListItem (props) {

    const cart = useContext(CartContext);

    return(
        <div className="container px-2">
            <div className="row cart-item">
                <div className="item-image d-flex col-md-3 col-4 my-3 justify-content-center">
                    <img src={props.images?.[0]?.src} alt=""></img>
                </div>
                <div className="item-attributes d-flex flex-column col-md-4 col-8 ">
                    <div className="item-details-bold-lg d-flex w-100 my-2">
                        <Link to={`/catalog/item/${props.id}`}>
                        <div className="line-item-name">
                            {props.name}
                        </div> 
                        </Link>                                                        
                    </div>                
                    <div className="item-details-lg mb-2">
                        { props?.size ? (<span>размер: {props.size}</span>) : ('')}
                    </div>
                </div>           
                <div className="col-md-2 col-8 d-flex flex-column justify-content-around">                                       
                    <div className="input-group input-group-sm  d-flex justify-content-end ">    
                        <button className="btn btn-sm  btn-light border shadow-none" type="button" id="input-decrease"
                        onClick={() => cart.setQuantity(props, props.quantity-1)}> 
                        - </button>                                           
                        <div className="value-cart-list-item">{props.quantity}</div>                                  
                        <button className="btn btn-sm  btn-light border shadow-none p-2" type="button" id="input-increase"
                        onClick={() => cart.setQuantity(props, props.quantity+1)}>
                        + </button>                                   
                    </div> 
                    <div className="remove-line-item p-0 d-flex justify-content-end">
                        <div type="button" className="border-bottom my-3"
                        onClick={() => cart.remove(props)}>Удалить</div>
                    </div>                                                       
                </div>
                <div className="col-md-3 col-4 d-flex justify-content-end ">                    
                    <div className="item-details-bold-lg my-3 me-3">                    
                        <span> {props.price} </span><FontAwesomeIcon icon={solid('ruble-sign')} size="sm" />
                    </div>
                </div>  
                                                                            
            </div>
            
        </div>      
        
    )
}