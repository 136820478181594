import {useContext} from 'react';
import { CartContext } from "../cart-context/cart-context";
// import { Spinner} from '../spinner'
import {Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import './order-processing-cart.css';

const OrderProcessingCart =() => {
    const cart = useContext(CartContext);
    
    const shippingCostRenderer =(shippingCostValue)=> {
        if( shippingCostValue == null) {    
            return(                
                <div className="col-12 d-flex justify-content-end">
                    <div className="cart-shipping"> выберите страну доставки </div>
                </div>
            );
        } else if(shippingCostValue === 0) {
            return(
                <>
                    <div className="col-6">
                        <div> доставка </div>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="cart-shipping"> бесплатная доставка </div>
                    </div>
                </>
            );
        } else {
            return(
                <>
                    <div className="col-8">
                        <div> доставка </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                        <div className="price-color">{shippingCostValue} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/> </div> 
                    </div>
                </>
            )
        } 
    }
    return (
        <div>
            {
                cart.items.map(item=> 
                    <div key={item.id} className="d-flex mb-3 order-proc-item-details">
                        <div className="col-2 item-image-sm position-relative">
                            <img className="rounded w-100" src={item.images[0]?.src} alt="earings"></img>
                            <span className=" position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">{item.quantity}</span>

                        </div>
                        <div className="col-7 d-flex flex-column">
                            <div className="d-flex w-100 ps-2">
                                <div className="item-details-bold-sm mb-1">
                                <Link to={`/catalog/item/${item.id}/`}>
                                   <span> {item.name} </span> 
                                </Link>                                
                                </div>                                       
                            </div>                            
                            <div className="item-details-sm mb-1 ps-2">
                                { item?.size ? (<span>размер: {item.size}</span>) : ('')}
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <div className="item-details-bold-sm mb-1">
                                <span>{item.price} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/> </span>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="row d-flex justify-content-end ">
                <div className="col-12  "> 
                        <div className="totals-sm">    
                            <div className="d-flex border-bottom border-top py-3 ">
                                <div className="col-8 ">
                                    <div> товары, {cart.items.length} </div>                                    
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <div> {cart.itemsCost} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></div>
                                </div>
                            </div>
                            <div className="row shipping-line my-3 ">   
                                {shippingCostRenderer(cart.deliveryCost)} 
                            </div>
                            <div className="d-flex border-bottom pb-3">
                                <div className="col-8">
                                    <div className=''> итого </div>
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <div>  {cart.totalCost} <FontAwesomeIcon icon={solid('ruble-sign')} size="sm"/></div>
                                </div>
                            </div>
                        </div>                        
                    </div> 
                </div>                        
            </div>    
    )
}

export default OrderProcessingCart;