import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CartContextProvider } from '../cart-context/cart-context';
import ScrollToTop from '../scroll-to-top/scroll-to-top';
import CartList from '../cart-list/cart-list';
import ShopList from '../shop-list/shop-list';
import ContentCatalog from '../content-catalog/content-catalog';
import Footer from '../footer/footer';
import ShopListItem from '../shop-list-item/shop-list-item';
import OrderProcessing from '../order-processing/order-processing';
import Navbar from '../navbar/navbar';
import Orders from '../orders/orders';
import OrderIdDetails from '../order-id-details/order-id-details';
import DeliveryPaymentTerms from '../delivery-payment-terms/delivery-payment-terms';
import ReturnExchangeTerms from '../return-exchange-terms/return-exchange-terms';
import Contact from '../contact/contact';
import AboutUs from '../about-us/about-us';
import AgreementPersonalData from '../agreement-personal-data/agreement-personal-data';
import PrivacyPolicy from '../privacy-policy/privacy-policy';
import SalesRuls from '../sales-rules/sales-rules';

import './app.css';
import React from 'react';
import ScrollButton from '../scroll-button/scroll-button';

function App() {
    return (
        <Router>
            <CartContextProvider>
                <ScrollToTop/>
                <ScrollButton/>
                <div className="app">
                    <Navbar/>
                    <main>
                        <Routes>
                            <Route path="/" element={<ShopList/>}>
                                <Route path="index.html" element={<ShopList/>}>
                                </Route>
                            </Route>
                            <Route path="/cart-list" element={<CartList/> }>

                            </Route>
                            <Route path="/catalog/earings" element={<ContentCatalog selectedCatalog="earings"/>}>
                            </Route>
                            <Route path="/catalog/necklaces" element={<ContentCatalog selectedCatalog="necklaces"/>}>
                            </Route>
                            <Route path="/catalog/rings" element={<ContentCatalog selectedCatalog="rings"/>}>
                            </Route>
                            <Route path="/catalog/brooch" element={<ContentCatalog selectedCatalog="brooch"/>}>
                            </Route>
                            <Route path="/catalog/braslets" element={<ContentCatalog selectedCatalog="braslets"/>}>
                            </Route>
                            <Route path="/catalog/accessories" element={<ContentCatalog selectedCatalog="accessories"/>}>
                            </Route>
                            <Route path="/catalog/sale" element={<ContentCatalog selectedCatalog="sale"/>}>
                            </Route>
                            <Route path="catalog/shop-all" element={<ContentCatalog selectedCatalog="shop-all"/>}>
                            </Route>
                            <Route path="/catalog/new" element={<ContentCatalog selectedCatalog="new"/>}>
                            </Route>

                            <Route path="/catalog/:category/item/:id" element={<ShopListItem/>}>
                            </Route>
                            <Route path="/catalog/item/:id" element={<ShopListItem/>}>
                            </Route>
                            
                            <Route path="/orders" element={<Orders/>}>
                            </Route>
                            <Route path="/orders/:orderKey" element={<OrderIdDetails/>}>
                            </Route>
                            <Route path="/checkout" element={<OrderProcessing/>}>
                            </Route>
                            <Route path="/delivery-terms" element={<DeliveryPaymentTerms/>}>
                            </Route>
                            <Route path="/return-terms" element={<ReturnExchangeTerms/>}>
                            </Route>
                            <Route path="/contact" element={<Contact/>}>
                            </Route>
                            <Route path="/about-us" element={<AboutUs/>}>
                            </Route>
                            <Route path="/agreement" element={<AgreementPersonalData/>}>
                            </Route>
                            <Route path="/privacyPolicy" element={<PrivacyPolicy/>}>
                            </Route>
                            <Route path="/sales-rules" element={<SalesRuls/>}>

                            </Route>
                            
                            <Route path="*" element={<ShopList/>}>
                            </Route>
                        </Routes>
                    </main>
                    <Footer/>
                </div>
            </CartContextProvider>
        </Router>
    );
}

export default App;
