
const Contact =() =>{
    return(
        <div>
            <div className="undernav"></div>
            <div className='container wrapper-content'>
                <div className='wrapper-terms'>
                    <h5>
                    Контакты
                    </h5>
                    <div>
                        <p className="py-3">
                        НДП Подлубная Елена Фёдоровна <br/>
                        ИНН 744814182626 <br/>
                        г.Челябинск <br/>
                        ул. Чичерина, 5 <br/>
                        Whatsapp, Viber, Telegram: +7-963-468-6934 <br/>
                        Тел.: +7-963-468-6934
                        </p>
                        <h6>
                        Челябинск
                        </h6>
                        <p>
                    
                        Бутик Milano<br/>
                        ул. Энгельса, 73 <br/>
                        ПН-ПТ 12:00 - 20:00 <br/>
                        СБ-ВС 12:00 – 18:00 <br/>
                        +7-351-270-04-91 (телефон) <br/>
                        +7-922-708-4646 (whatsapp) <br/>
                        Оплата возможна наличными, картой.
                        </p>
                        
                        <p>
                        Шоу-рум AL <br/>
                        ул. Энгельса, 52 <br/>
                        ПН-ПТ 12:00-20:00 <br/>
                        +7-963-468-6934 (телефон и whatsapp) <br/>
                        Оплата возможна наличными, переводом.
                        </p>
                                   
                    </div>    
                </div>            
            </div>
        </div>
    )
}

export default Contact;