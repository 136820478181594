import './sort-component.css';

const SortComponent =() =>{




    return(
        <div className=" wrapper-sort border">
            <div className="row d-flex justify-content-end">
                <div className="col-12 col-md-5 d-flex justify-content-end">
                    <span className='pe-2' >
                        <label className="col-form-label" htmlFor="inputGroupSelect01">Сортировать</label>
                    </span>
                    <div className="d-flex align-items-center">
                        <select 
                        className="form-select form-select-sm" 
                        aria-label="Default select example" 
                        id="inputGroupSelect01">
                            <option value="1">Сначала новинки</option>
                            <option value="2">Цена по возрастанию</option>
                            <option value="3">Цена по убыванию</option>
                            <option value="4">По популярности</option>
                        </select>
                    
                    </div>

                </div>
                
            </div>
            
                
            
            
        </div>
        
        

    )
}

export default SortComponent;