import SkeletonBanner from '../skeleton-banner/skeleton-banner';
import SkeletonCard from '../skeleton-card/skeleton-card';
import SideNavbar from '../side-navbar/side-navbar';
import SortComponent from '../sort-component/sort-component';

const ContentCatalogLoading =(props) => {

    return(
                <div className="container wrapper-content ">
                    <div className="under-fix-nav">&nbsp;</div>
                    <div className="row "> 
                        <SideNavbar selectedMenu={props.selectedCatalog}/>
                        <div className="col-xl-9">
                            <SkeletonBanner/>
                            <div className="row">
                                <div className="col-12">
                                    <SortComponent/>
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-6">
                                    <SkeletonCard/>
                                </div>
                                <div className="col-md-4 col-6">
                                    <SkeletonCard/>
                                </div>
                                <div className="col-md-4 col-6">
                                    <SkeletonCard/>
                                </div>
                            </div>                            
                        </div>                                            
                    </div>
                </div>     

    )

}
export default ContentCatalogLoading;