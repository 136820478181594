import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';
import {useContext} from 'react';
import { CartContext } from "../cart-context/cart-context";

const CartIcon = () => {
    const cart = useContext(CartContext);
    return (
        <Link to="/cart-list">
            <div className="cart-icon position-relative">
                <i style={{fontSize:"20px"}} className=" bi bi-bag"></i>
                <span style={{marginLeft:"5px"}} className=" position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-secondary cart-span">{cart.items.length}</span> 
            </div>
        </Link>

        

    )
}

export default CartIcon;